<template>
  <n-chip :class="color" class="self-center">
    {{ label }}
  </n-chip>
</template>

<script>
import { NChip } from '@careerpartner/nitro';
import { PLANNING_STATUS } from '@/constants/planning-status';
import { MIXINS } from '@/mixins';
export default {
  name: 'PlanningStatusChip',
  components: {
    NChip,
  },
  mixins: [MIXINS],

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  computed: {
    label() {
      return this.planningStatusFormatter(this.value);
    },

    color() {
      switch (this.value) {
        case PLANNING_STATUS.PUBLISHED:
          return 'bg-green-600';
        case PLANNING_STATUS.WITHDRAWN:
          return 'bg-gray-700';
        case PLANNING_STATUS.DRAFT:
          return 'bg-orange-600';
        default:
          return '';
      }
    },
  },
};
</script>
