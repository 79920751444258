import { ProductOfferApi } from '@/services/product-offer-api';
import { router } from '@/router';

export default {
  async loadCourseOffer({ commit }, courseOfferId) {
    if (!courseOfferId) return;

    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);

    try {
      const response = await ProductOfferApi.getCourseOffer(courseOfferId);
      commit('setCourseOffer', response.data);
    } finally {
      commit('setLoading', false);
    }
  },

  async loadCourseOfferEditability({ commit }, courseOfferId) {
    if (!courseOfferId) return;

    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);

    try {
      const response = await ProductOfferApi.getCourseOfferEditability(
        courseOfferId
      );

      commit('setCourseOfferEditability', response?.data?.value || false);
    } finally {
      commit('setLoading', false);
    }
  },

  async createCourseOffer({ commit, dispatch }, courseOffer) {
    if (!courseOffer) return;

    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);

    try {
      const response = await ProductOfferApi.createCourseOffer(courseOffer);

      await router.push({
        name: 'course-offer-detail',
        params: { courseOfferId: response.id },
      });

      dispatch(
        'snackbar/updateSnackbar',
        {
          message: 'Fachangebot wurde erfolgreich gespeichert',
        },
        { root: true }
      );
    } finally {
      commit('setLoading', false);
    }
  },

  async updateCourseOffer({ commit, dispatch }, courseOffer) {
    if (!courseOffer) return;

    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);

    try {
      const response = await ProductOfferApi.updateCourseOffer(courseOffer);

      await router.push({
        name: 'course-offer-detail',
        params: { courseOfferId: response.id },
      });

      dispatch(
        'snackbar/updateSnackbar',
        {
          message: 'Fachangebot wurde erfolgreich aktualisiert',
        },
        { root: true }
      );
    } finally {
      commit('setLoading', false);
    }
  },

  async deleteCourseOffer({ commit, dispatch }, courseOfferId) {
    if (!courseOfferId) return;

    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);

    try {
      await ProductOfferApi.deleteCourseOffer(courseOfferId);

      await router.push({
        name: 'course-offer-list',
      });

      dispatch(
        'snackbar/updateSnackbar',
        {
          message: 'Fachangebot wurde erfolgreich gelöscht',
        },
        { root: true }
      );
    } finally {
      commit('setLoading', false);
    }
  },
  async getPossibleTransitions({ commit }, courseOfferId) {
    if (!courseOfferId) return;
    let response;

    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);

    try {
      response = await ProductOfferApi.getPossibleTransitions(courseOfferId);
    } finally {
      commit('setLoading', false);
    }
    return response;
  },

  async publishCourseOffer({ commit, dispatch }, courseOfferId) {
    if (!courseOfferId) return;

    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);

    try {
      await ProductOfferApi.publishCourseOfferPlanningStatus(courseOfferId);
      dispatch(
        'snackbar/updateSnackbar',
        {
          message: 'Fachangebot erfolgreich veröffentlicht.',
        },
        { root: true }
      );
    } finally {
      commit('setLoading', false);
    }
  },

  async republishCourseOffer({ commit, dispatch }, courseOfferId) {
    if (!courseOfferId) return;

    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);

    try {
      await ProductOfferApi.republishCourseOfferPlanningStatus(courseOfferId);
      dispatch(
        'snackbar/updateSnackbar',
        {
          message:
            'Fachangebot erfolgreich veröffentlicht. Fachangebot kann ab sofort von Studierenden gebucht werden.',
        },
        { root: true }
      );
    } finally {
      commit('setLoading', false);
    }
  },

  async unpublishCourseOffer({ commit, dispatch }, courseOfferId) {
    if (!courseOfferId) return;

    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);

    try {
      await ProductOfferApi.unpublishCourseOffer(courseOfferId);
      dispatch(
        'snackbar/updateSnackbar',
        {
          message:
            'Fachangebot erfolgreich als Entwurf zurückgesetzt. Fachangebot kann ab sofort nicht mehr von Studierenden gebucht werden.',
        },
        { root: true }
      );
    } finally {
      commit('setLoading', false);
    }
  },

  async withdrawCourseOffer({ commit, dispatch }, courseOfferId) {
    if (!courseOfferId) return;

    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);

    try {
      await ProductOfferApi.withdrawCourseOffer(courseOfferId);
      dispatch(
        'snackbar/updateSnackbar',
        {
          message:
            'Fachangebot erfolgreich zurückgenommen. Fachangebot kann ab sofort nicht mehr von Studierenden gebucht werden.',
        },
        { root: true }
      );
    } finally {
      commit('setLoading', false);
    }
  },

  async getAcademicStaffProfiles({ commit }, name) {
    const { data } = await ProductOfferApi.getAcademicStaffProfiles(name);

    commit('setAcademicStaffProfiles', data);
  },

  async getAcademicStaffAssignments({ commit }, courseOfferId) {
    try {
      commit('setIsLoadingAcademicStaffAssignments', true);
      commit('resetAcademicStaffAssignments');

      const data = await ProductOfferApi.getAcademicStaffAssignments(
        courseOfferId
      );

      commit('setAcademicStaffAssignments', data);
    } finally {
      commit('setIsLoadingAcademicStaffAssignments', false);
    }
  },

  resetAcademicStaffAssignments({ commit }) {
    commit('resetAcademicStaffAssignments');
  },

  async getInvalidAcademicStaffAssignments({ commit }, courseOfferId) {
    try {
      commit('setIsLoadingInvalidAcademicStaffAssignments', true);
      commit('resetInvalidAcademicStaffAssignments');

      const data = await ProductOfferApi.getInvalidAcademicStaffAssignments(
        courseOfferId
      );

      commit('setInvalidAcademicStaffAssignments', data);
    } finally {
      commit('setIsLoadingInvalidAcademicStaffAssignments', false);
    }
  },

  resetInvalidAcademicStaffAssignments({ commit }) {
    commit('resetInvalidAcademicStaffAssignments');
  },

  async createAcademicStaffAssignment(
    { commit, state },
    academicStaffAssignment
  ) {
    commit('setIsLoadingAcademicStaffAssignments', true);
    try {
      await ProductOfferApi.createAcademicStaffAssignment({
        courseOfferId: state.courseOffer.id,
        ...academicStaffAssignment,
      });
    } finally {
      commit('setIsLoadingAcademicStaffAssignments', false);
    }
  },

  async updateAcademicStaffAssignmentPeriod(
    { commit, state },
    academicStaffAssignment
  ) {
    commit('setIsLoadingAcademicStaffAssignments', true);

    try {
      await ProductOfferApi.updateAcademicStaffAssignmentPeriod(
        state.courseOffer.id,
        academicStaffAssignment
      );
    } finally {
      commit('setIsLoadingAcademicStaffAssignments', false);
    }
  },

  async deleteAcademicStaffAssignment(
    { commit, state },
    academicStaffAssignmentId
  ) {
    try {
      commit('setIsLoadingAcademicStaffAssignments', true);
      commit('resetAcademicStaffAssignments');

      await ProductOfferApi.deleteAcademicStaffAssignment(
        state.courseOffer.id,
        academicStaffAssignmentId
      );
      const data = await ProductOfferApi.getAcademicStaffAssignments(
        state.courseOffer.id
      );

      commit('setAcademicStaffAssignments', data);
    } finally {
      commit('setIsLoadingAcademicStaffAssignments', false);
    }
  },
};
