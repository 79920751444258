<template>
  <n-banner
    v-if="hasErrors"
    variant="error"
    class="fixed z-[1001] left-0 right-0 top-0"
    @close="dismissError"
  >
    <template #message>
      {{ lastErrorFormatted }}
    </template>
  </n-banner>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { NBanner } from '@careerpartner/nitro';

export default {
  name: 'ErrorBanner',
  components: {
    NBanner,
  },
  computed: {
    ...mapGetters('errors', ['hasErrors', 'lastErrorFormatted']),
  },
  methods: {
    ...mapActions('errors', ['dismissError']),
    reloadPage() {
      location.reload();
    },
  },
};
</script>
