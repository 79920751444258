import { getField, updateField } from 'vuex-map-fields';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    loading: false,
    moduleOffer: null,
    moduleOfferEditable: false,
  },
  actions,
  getters: {
    getField,
    ...getters,
  },
  mutations: {
    updateField,
    ...mutations,
  },
};
