export default {
  hasFilters: (state) => Object.keys(state.filter).length > 0,

  getStatusFilter({ filter }) {
    return 'string' === typeof filter?.status ? filter.status.split(',') : [];
  },

  getTeachingFormatFilter({ filter }) {
    return 'string' === typeof filter?.teachingFormat
      ? filter.teachingFormat.split(',')
      : [];
  },

  getFilter({ filter }, getters) {
    const status = getters.getStatusFilter;
    const teachingFormat = getters.getTeachingFormatFilter;

    const transformedFilters = {};

    if (status.length > 0) {
      transformedFilters.status = status;
    }

    if (teachingFormat.length > 0) {
      transformedFilters.teachingFormat = teachingFormat;
    }

    return {
      ...filter,
      ...transformedFilters,
    };
  },
};
