export default {
  setLoading(state, payload) {
    state.loading = payload;
  },

  setModuleOffer(state, payload) {
    state.moduleOffer = payload;
  },

  setModuleOfferEditability(state, payload) {
    state.moduleOfferEditable = payload;
  },
};
