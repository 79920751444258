<template>
  <div class="grid grid-cols-2 gap-8 mt-16">
    <ProductInfo
      :has-product="hasCorrelatingProduct"
      :value="product?.defaultTitle"
    >
      <template #title><slot name="productNameTitle"></slot></template>
    </ProductInfo>
    <ProductInfo :has-product="hasCorrelatingProduct" :value="formattedCode">
      <template #title><slot name="productCodeTitle"></slot></template>
    </ProductInfo>
    <ProductInfo
      :has-product="hasCorrelatingProduct"
      :value="formattedVersionCode"
    >
      <template #title><slot name="productVersionCodeTitle"></slot></template>
    </ProductInfo>
    <ProductInfo :has-product="hasCorrelatingProduct" :value="product?.ects">
      <template #title><slot name="productECTSTitle"></slot></template>
    </ProductInfo>
  </div>
</template>
<script>
import ProductInfo from './ProductInfo.vue';
export default {
  name: 'ProductInformationSection',
  components: { ProductInfo },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasCorrelatingProduct() {
      return this.product !== null;
    },
    formattedCode() {
      if (!this.product) {
        return 'Unbekannt';
      }
      return this.product.courseCode || this.product.moduleCode;
    },
    formattedVersionCode() {
      if (!this.product) {
        return 'Unbekannt';
      }
      return (
        this.product.courseMainVersionCode || this.product.moduleMainVersionCode
      );
    },
  },
};
</script>
