<template>
  <td class="text-black font-bold truncate">
    <router-link
      :to="{
        name: 'module-offer-detail',
        params: { moduleOfferId: item.id },
      }"
    >
      <span :title="item.name">{{ item.name }}</span>
    </router-link>
  </td>
  <td class="text-black">
    <span>
      <span class="font-bold">
        {{ item.moduleMainVersion?.moduleCode }}
      </span>
      <div class="text-cta inline-flex">
        <n-tooltip :content="item.moduleMainVersion?.defaultTitle">
          <n-icon icon="information-outline" />
        </n-tooltip>
      </div>
    </span>
    <br />
    <div class="text-gray-700 text-xs truncate">
      <span :title="item.moduleMainVersion?.moduleMainVersionCode">
        Version: {{ item.moduleMainVersion?.moduleMainVersionCode }}
      </span>
    </div>
  </td>
  <td class="text-black">
    {{ yearSectionWithYearFormatter(item) }}
  </td>
  <td>
    <planning-status-chip :value="item.planningStatus" />
  </td>
  <td>
    <n-tooltip v-if="isOnsiteOffer" :content="onsiteOfferWarningText">
      <n-icon class="text-orange-600" icon="alert" />
    </n-tooltip>
    <div v-else>
      <enrollment-state-icon :value="item.enrollmentState" />

      <div v-if="constraints.hasConflictingBooking" class="inline-flex ml-2">
        <n-tooltip content="Blockierende CARE-Konfiguration">
          <n-icon class="text-orange-600" icon="alert" />
        </n-tooltip>
      </div>
    </div>
  </td>
  <td>
    {{ originFormatter(item) }}
  </td>
  <td>
    <n-tooltip v-if="isOnsiteOffer" :content="onsiteOfferWarningText">
      <n-icon class="text-orange-600" icon="alert" />
    </n-tooltip>
    <attendee-visualization v-else :current-value="item.enrollmentsCount" />
  </td>
</template>

<script>
import { NIcon, NTooltip } from '@careerpartner/nitro';
import { hasConflictingBookingConstraints } from '@/utils/hasConflictingBookingConstraint';
import PlanningStatusChip from '@/components/PlanningStatusChip';
import EnrollmentStateIcon from '@/components/EnrollmentStateIcon';
import AttendeeVisualization from '../components/AttendeeVisualization.vue';
import { MIXINS } from '@/mixins';

export default {
  name: 'ModuleOfferTableRow',
  components: {
    PlanningStatusChip,
    NIcon,
    NTooltip,
    EnrollmentStateIcon,
    AttendeeVisualization,
  },
  mixins: [MIXINS],

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    constraints() {
      return {
        hasConflictingBooking: hasConflictingBookingConstraints(this.item),
      };
    },
    isOnsiteOffer() {
      return !this.item.isDistanceLearningOffer;
    },
    onsiteOfferWarningText() {
      return 'Wert liegt nicht vor. Angebot wurde noch nicht vollständig migriert';
    },
  },
};
</script>

<style scoped>
.module-offer-table-row--warning::after {
  @apply absolute left-0 top-0 bottom-0 w-2 bg-orange-600;
  content: '';
}
</style>
