<template>
  <div>
    <n-table
      :header-items="headerItems"
      :data="filteredAndSortedData"
      :is-loading="isLoading"
    >
      <template #filter-name="{ close }">
        <n-table-filter-radio
          id="enhanced-academic-staff-assignments-table__filter-name"
          :options="filtersByNameOptions"
          :model-value="filterName"
          icon="sort-alphabetical-ascending"
          data-test="table-filter-by-name"
          @update:model-value="filterByName($event)"
          @close="close"
        />
      </template>
      <template #filter-role="{ close }">
        <n-table-filter-checkbox
          id="enhanced-academic-staff-assignments-table__filter-role"
          :options="filtersByRoleOptions"
          :model-value="filterRole"
          data-test="table-filter-by-role"
          @update:model-value="filterByRole($event)"
          @close="close"
        />
      </template>

      <template #row="{ item }">
        <n-table-row :item="item">
          <td class="py-6">
            <academic-staff-assignment-profile-full-name
              :assignment="item"
            ></academic-staff-assignment-profile-full-name>
          </td>
          <td class="py-6">
            {{ academicStaffAssignmentRoleFormatter(item.role) }}
          </td>
          <td class="py-6">{{ shortDate(item.assignmentStartDate) }}</td>
          <td class="py-6">{{ shortDate(item.assignmentEndDate) }}</td>
          <td class="py-6 flex justify-around">
            <n-tooltip :content="editIconTooltipText(item)">
              <n-button
                variant="quaternary"
                :disabled="
                  !isEditable(item) || !userCanEditAcademicStaffAssignment
                "
                @click="$emit('editAssignment', item)"
              >
                <n-icon icon="pencil-outline" />
              </n-button>
            </n-tooltip>
            <n-tooltip :content="deleteIconTooltipText()">
              <n-button
                variant="tertiary"
                :disabled="!userCanDeleteAcademicStaffAssignment"
                @click="openDeleteModal(item)"
              >
                <n-icon icon="trash-can-outline" />
              </n-button>
            </n-tooltip>
          </td>
        </n-table-row>
      </template>

      <template #no-results>
        <n-empty-results
          v-if="roleFilterNotEmpty"
          text="Keine Suchergebnisse gefunden"
          reset-text="Suche & Filter zurücksetzen"
          @reset="resetFilter"
        />
        <n-empty-data v-else text="Keine Lehrenden zugeordnet" />
      </template>
    </n-table>

    <n-dialog
      :subject="dialogQuestion"
      :description="dialogDescription"
      :is-open="isModalOpen"
      @close="closeModal"
    >
      <template #image>
        <img :src="trash" alt="Locked warning" class="w-12" />
      </template>
      <template #actions>
        <div class="flex w-full mt-12 justify-center">
          <n-button class="mr-2" variant="secondary" @click="closeModal">
            Abbrechen
          </n-button>
          <n-button class="ml-2" @click="deleteAssignment">Löschen</n-button>
        </div>
      </template>
    </n-dialog>
  </div>
</template>

<script>
import { IMAGE_URLS } from '@/constants';
import { hasPermissionFor } from '@/utils/permissionUtil';
import AcademicStaffAssignmentProfileFullName from '@/components/AcademicStaffAssignmentProfileFullName.vue';
import { MIXINS } from '@/mixins';

export default {
  name: 'EnhancedAcademicStaffAssignmentsTable',

  components: {
    AcademicStaffAssignmentProfileFullName,
  },

  mixins: [MIXINS],

  props: {
    data: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isOfferDesynchronized: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['editAssignment', 'delete'],

  data: () => ({
    headerItems: [
      {
        class: 'w-4/12 px-3',
        label: 'Name',
        key: 'name',
      },
      {
        class: 'w-2/12 px-3',
        label: 'Rolle',
        key: 'role',
      },
      {
        class: 'w-2/12 px-3',
        label: 'Start',
        key: 'start',
      },
      {
        class: 'w-2/12 px-3',
        label: 'Ende',
        key: 'end',
      },
      {
        class: 'w-2/12 px-3',
        label: '',
        key: 'actions',
      },
    ],
    filtersByNameOptions: [
      { value: 'asc', label: 'Aufsteigend' },
      { value: 'desc', label: 'Absteigend' },
    ],
    filtersByRoleOptions: [
      { value: 'TUTOR_FS', label: 'Tutor' },
      { value: 'CO_CORRECTOR_EXAMINATION_TOOLS', label: 'Zweitkorrektor' },
    ],
    filterName: 'asc',
    filterRole: [],
    selectedForDelete: null,
  }),

  computed: {
    filteredAndSortedData() {
      let d = this.data;

      if (this.filterRole.length > 0) {
        d = d.filter((item) => this.filterRole.includes(item.role));
      }

      if (this.filterName === 'asc') {
        d = d.sort((a, b) => {
          if (!a.profile) {
            return 1;
          }
          if (!b.profile) {
            return -1;
          }

          return a.profile.givenName.localeCompare(b.profile.givenName);
        });
      } else {
        d = d.sort((a, b) => {
          if (!b.profile) {
            return 1;
          }
          if (!a.profile) {
            return -1;
          }

          return b.profile.givenName.localeCompare(a.profile.givenName);
        });
      }

      return d;
    },

    userCanDeleteAcademicStaffAssignment() {
      return hasPermissionFor('academic-staff-assignment', ['d']);
    },

    userCanEditAcademicStaffAssignment() {
      return hasPermissionFor('academic-staff-assignment', ['u']);
    },

    trash() {
      return IMAGE_URLS.TRASH;
    },

    isModalOpen() {
      return !!this.selectedForDelete;
    },

    roleFilterNotEmpty() {
      return this.filterRole.length > 0;
    },
    dialogQuestion() {
      return 'Bist du sicher, dass du die Zuordnung löschen möchtest?';
    },
    dialogDescription() {
      return this.selectedForDelete?.careCourseLectureSeriesLecturerId
        ? 'Die Lehrendenzuordnung wird hiermit in Epos gelöscht. Die Zuordnung in Care bleibt jedoch erhalten. Zukünftige Änderungen an dieser spezifischen Zuordnung, welche aus Care angestoßen werden, werden hiermit ignoriert.'
        : 'Zuordnungen werden archiviert und können NICHT wiederhergestellt werden.';
    },
  },

  methods: {
    filterByName(name) {
      this.filterName = name;
    },

    filterByRole(role) {
      this.filterRole = role;
    },

    openDeleteModal(assignment) {
      this.selectedForDelete = assignment;
    },

    deleteAssignment() {
      this.$emit('delete', this.selectedForDelete.id);
      this.closeModal();
    },

    closeModal() {
      this.selectedForDelete = null;
    },

    isEditable(item) {
      return (
        this.isOfferDesynchronized || !item.careCourseLectureSeriesLecturerId
      );
    },

    editIconTooltipText(item) {
      if (!this.isEditable(item)) {
        return 'Diese Lehrendenzuordnung muss weiterhin in Care verwaltet werden.';
      } else if (!this.userCanEditAcademicStaffAssignment) {
        return 'Du hast nicht die nötigen Berechtigungen zum Bearbeiten.';
      }

      return 'Zeitraum bearbeiten';
    },

    deleteIconTooltipText() {
      if (!this.userCanEditAcademicStaffAssignment) {
        return 'Du hast nicht die nötigen Berechtigungen zum Löschen.';
      }

      return 'Löschen';
    },

    resetFilter() {
      this.filterName = 'asc';
      this.filterRole = [];
    },
  },
};
</script>
