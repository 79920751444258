<template>
  <view-wrapper v-if="!loading && !!moduleOffer">
    <template #headline>
      <div class="flex">
        <p>Modulangebot bearbeiten</p>
        <planning-status-chip
          class="ml-2"
          :value="moduleOffer.planningStatus"
        />
      </div>
    </template>
    <validation-form v-slot="{ meta }" :initial-values="offerFormData">
      <n-toolbar>
        <n-toolbar-button
          text="Speichern"
          icon="mdi-content-save-outline"
          :disabled="!hasChanged || !meta.valid || !assignedModule"
          @click="saveData"
        />
      </n-toolbar>
      <offer-form-layout-wrapper
        offer-type-label="Modulangebot"
        assignment-selection-headline="Modulinformationen"
      >
        <template #assignment-selection>
          <module-offer-module-assignment
            :assigned-module="assignedModule"
            :disabled="true"
            @module-selected="setAssignedModule"
          />
        </template>

        <template #form>
          <OfferInformationForm
            v-model="offerFormData"
            @has-changed="(changed) => (hasChanged = changed)"
          />
        </template>
      </offer-form-layout-wrapper>
    </validation-form>
  </view-wrapper>
  <view-wrapper v-else>
    <div class="flex flex-col items-center mt-12">
      <n-simple-loader />
      <p class="text-cta">Modulangebot wird geladen...</p>
    </div>
  </view-wrapper>
</template>

<script>
import { NToolbar, NToolbarButton, NSimpleLoader } from '@careerpartner/nitro';
import ViewWrapper from '@/components/ViewWrapper';
import ModuleOfferModuleAssignment from '@/components/ModuleOfferModuleAssignment';
import OfferInformationForm from '@/components/ModuleOfferInformationForm';
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import PlanningStatusChip from '@/components/PlanningStatusChip.vue';
import {
  mapModuleOfferToAPI,
  mapModuleOfferToView,
} from '@/utils/mapModuleOffer';
import OfferFormLayoutWrapper from '@/components/OfferFormLayoutWrapper';

export default {
  name: 'ModuleOfferUpdateView',

  components: {
    NToolbar,
    NToolbarButton,
    ViewWrapper,
    OfferFormLayoutWrapper,
    ModuleOfferModuleAssignment,
    OfferInformationForm,
    NSimpleLoader,
    PlanningStatusChip,
  },

  beforeRouteEnter(to, _from, next) {
    if (!to.params.moduleOfferId) return;
    const { moduleOfferId } = to.params;
    next((vm) => {
      vm.loadModuleOffer(moduleOfferId);
    });
  },

  data: () => ({
    assignedModule: null,
    name: '',
    year: null,
    yearSection: null,
    hasChanged: false,
  }),

  computed: {
    ...mapFields('moduleOffer', ['loading', 'moduleOffer']),

    offerFormData: {
      get() {
        return {
          name: this.name.trim(),
          year: this.year,
          yearSection: this.yearSection,
        };
      },
      set(value) {
        this.name = value.name.trim();
        this.year = value.year;
        this.yearSection = value.yearSection;
      },
    },
  },

  watch: {
    moduleOffer(newVal) {
      const mappedViewValue = mapModuleOfferToView(newVal);

      this.name = mappedViewValue.name;
      this.year = mappedViewValue.year;
      this.yearSection = mappedViewValue.yearSection;
      this.assignedModule = mappedViewValue.module;

      this.yearSectionEnabled = !!this.year;
    },
  },

  methods: {
    ...mapActions('moduleOffer', ['loadModuleOffer', 'updateModuleOffer']),
    ...mapActions('snackbar', ['updateSnackbar']),

    async saveData() {
      const mappedValue = mapModuleOfferToAPI(
        this.offerFormData,
        this.assignedModule
      );

      const mappedValueWithId = {
        ...mappedValue,
        id: this.moduleOffer.id,
      };

      await this.updateModuleOffer(mappedValueWithId);
    },

    setAssignedModule(assignedModule) {
      this.assignedModule = assignedModule;
    },
  },
};
</script>
