export const sortOrder = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const getDefaultSortOrder = (entityKey) => {
  return {
    key: entityKey + '.name',
    order: sortOrder.ASC,
  };
};
