<template>
  <view-wrapper v-if="!loading && !!courseOffer">
    <template #headline>
      <div class="flex">
        <p>Fachangebot bearbeiten</p>
        <planning-status-chip
          class="ml-2"
          :value="courseOffer.planningStatus"
        />
      </div>
    </template>
    <validation-form v-slot="{ meta }" :initial-values="offerFormData">
      <n-toolbar>
        <n-toolbar-button
          text="Speichern"
          icon="mdi-content-save-outline"
          :disabled="!meta.valid || !hasChanged || !assignedCourse"
          @click="saveData"
        />
      </n-toolbar>

      <offer-form-layout-wrapper
        offer-type-label="Fachangebot"
        assignment-selection-headline="Fachinformationen"
      >
        <template #assignment-selection>
          <course-offer-course-assignment
            :assigned-course="assignedCourse"
            :disabled="true"
            @course-selected="setAssignedCourse"
          />
        </template>

        <template #form>
          <OfferInformationForm
            v-model="offerFormData"
            @has-changed="(changed) => (hasChanged = changed)"
          />
        </template>
      </offer-form-layout-wrapper>
    </validation-form>
  </view-wrapper>
  <view-wrapper v-else>
    <div class="flex flex-col items-center mt-12">
      <n-simple-loader />
      <p class="text-cta">Fachangebot wird geladen...</p>
    </div>
  </view-wrapper>
</template>

<script>
import { NToolbar, NToolbarButton, NSimpleLoader } from '@careerpartner/nitro';
import ViewWrapper from '@/components/ViewWrapper';
import CourseOfferCourseAssignment from '@/components/CourseOfferCourseAssignment';
import OfferInformationForm from '@/components/CourseOfferInformationForm';
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import PlanningStatusChip from '@/components/PlanningStatusChip.vue';
import {
  mapCourseOfferToAPI,
  mapCourseOfferToView,
} from '@/utils/mapCourseOffer';
import OfferFormLayoutWrapper from '@/components/OfferFormLayoutWrapper';
import { didEnrollmentPeriodValuesChange } from '@/utils/did-enrollment-periods-change';

export default {
  name: 'CourseOfferUpdateView',

  components: {
    NToolbar,
    NToolbarButton,
    ViewWrapper,
    CourseOfferCourseAssignment,
    OfferInformationForm,
    NSimpleLoader,
    PlanningStatusChip,
    OfferFormLayoutWrapper,
  },

  beforeRouteEnter(to, _from, next) {
    if (!to.params.courseOfferId) return;
    const { courseOfferId } = to.params;
    next((vm) => {
      vm.loadCourseOffer(courseOfferId);
    });
  },

  data: () => ({
    assignedCourse: null,
    name: '',
    year: null,
    yearSection: null,
    teachingFormat: null,
    startDate: null,
    endDate: null,
    maxParticipants: null,
    hasChanged: false,
  }),

  computed: {
    offerFormData: {
      get() {
        return {
          id: this.courseOffer.id,
          name: this.name.trim(),
          year: this.year,
          yearSection: this.yearSection,
          teachingFormat: this.teachingFormat,
          startDate: this.startDate,
          endDate: this.endDate,
          maxParticipants: this.maxParticipants,
        };
      },
      set(value) {
        this.name = value.name.trim();
        this.year = value.year;
        this.yearSection = value.yearSection;
        this.teachingFormat = value.teachingFormat;
        this.startDate = value.startDate;
        this.endDate = value.endDate;
        this.maxParticipants = value.maxParticipants;
      },
    },
    ...mapFields('courseOffer', ['loading', 'courseOffer']),
  },

  watch: {
    courseOffer(newVal) {
      const mappedViewValue = mapCourseOfferToView(newVal);

      this.name = mappedViewValue.name;
      this.year = mappedViewValue.year;
      this.yearSection = mappedViewValue.yearSection;
      this.teachingFormat = mappedViewValue.teachingFormat;
      this.startDate = mappedViewValue.startDate;
      this.endDate = mappedViewValue.endDate;
      this.maxParticipants = mappedViewValue.maxParticipants;
      this.assignedCourse = mappedViewValue.course;

      this.yearSectionEnabled = !!this.year;
      this.endDateEnabled = !!this.endDate;
      this.attendanceNumberEnabled = !!this.maxParticipants;
    },
  },

  methods: {
    ...mapActions('courseOffer', ['loadCourseOffer', 'updateCourseOffer']),
    ...mapActions('snackbar', ['updateSnackbar']),

    async saveData() {
      const initialStartDate = this.courseOffer.enrollmentStartDate;
      const initialEndDate = this.courseOffer.enrollmentEndDate;

      const epChanged = didEnrollmentPeriodValuesChange(
        initialStartDate,
        initialEndDate,
        this.startDate,
        this.endDate
      );

      const mappedValue = mapCourseOfferToAPI(
        this.offerFormData,
        this.assignedCourse
      );

      if (!epChanged) {
        mappedValue.enrollmentStartDate = initialStartDate;
        mappedValue.enrollmentEndDate = initialEndDate;
      }

      const mappedValueWithId = {
        ...mappedValue,
        id: this.courseOffer.id,
      };

      await this.updateCourseOffer(mappedValueWithId);
    },

    setAssignedCourse(assignedCourse) {
      this.assignedCourse = assignedCourse;
    },
  },
};
</script>
