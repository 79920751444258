<template>
  <n-search-bar
    id="list-search-bar"
    v-model="internalValue"
    :model-value="trimmedSearchTerm"
    :label="label"
    @update:model-value="$emit('search', trimmedSearchTerm)"
  />
</template>

<script>
import { NSearchBar } from '@careerpartner/nitro';

export default {
  name: 'ListSearchBar',

  components: { NSearchBar },

  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  emits: { search: null },

  data() {
    return {
      internalValue: null,
    };
  },

  computed: {
    trimmedSearchTerm() {
      if (!this.internalValue) {
        return null;
      }
      return this.internalValue.trim();
    },
  },

  watch: {
    value(val) {
      this.internalValue = val;
    },
  },
};
</script>
