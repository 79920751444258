<template>
  <n-dialog
    :is-open="value"
    :subject="texts.question"
    :description="texts.details"
    :extended="isRequestedPlanningStatusWithdraw"
    @close="$emit('close')"
  >
    <template #image>
      <img :src="modalImage" alt="transition image" />
    </template>
    <template #default>
      <div class="mt-7 mx-6 mb-5 flex flex-col items-center">
        <div
          v-if="isRequestedPlanningStatusWithdraw"
          class="mt-5 py-3 px-4 border-l-8 border-orange-600 bg-orange-50 text-orange-600 text-xs"
        >
          <p class="flex items-center font-bold">
            <n-icon icon="alert-outline" /><span class="ml-1">Warnung</span>
          </p>
          <p>
            Es sind bereits Teilnehmer in das {{ productName }} eingeschrieben.
          </p>
        </div>
      </div>
    </template>
    <template #actions>
      <div class="w-full flex justify-around items-center">
        <n-button variant="secondary" @click="$emit('close')">
          Abbrechen
        </n-button>
        <n-button
          variant="primary"
          :class="ctaStyle"
          @click="onTransitionConfirmed"
        >
          {{ texts.ctaText }}
        </n-button>
      </div>
    </template>
  </n-dialog>
</template>
<script>
import { NDialog, NButton, NIcon } from '@careerpartner/nitro';
import { IMAGE_URLS } from '../constants';
import { PLANNING_STATUS } from '@/constants/planning-status';
export default {
  name: 'PlanningStatusTranistionConfirmationModal',
  components: {
    NDialog,
    NButton,
    NIcon,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    requestedPlanningStatus: {
      type: String,
      default: null,
      validator: (prop) => Object.keys(PLANNING_STATUS).includes(prop),
    },
    currentPlanningStatus: {
      type: String,
      default: null,
      validator: (prop) => Object.keys(PLANNING_STATUS).includes(prop),
    },
    courseOfferId: {
      type: String,
      default: null,
    },
    productName: {
      type: String,
      required: true,
      default: 'Fachangebot',
    },
  },
  emits: ['close', 'unpublish', 'publish', 'republish', 'withdraw'],
  computed: {
    modalImage() {
      switch (this.requestedPlanningStatus) {
        case PLANNING_STATUS.PUBLISHED:
          return IMAGE_URLS.BACKPACK;
        case PLANNING_STATUS.DRAFT:
        case PLANNING_STATUS.WITHDRAWN:
          return IMAGE_URLS.BACKPACK_DELETE;
        default:
          return IMAGE_URLS.BACKPACK;
      }
    },
    texts() {
      let question = '';
      let details = '';
      let ctaText = 'Bestätigen';
      switch (this.requestedPlanningStatus) {
        case PLANNING_STATUS.PUBLISHED:
          if (this.currentPlanningStatus === PLANNING_STATUS.DRAFT) {
            question = `Bist du sicher, dass du das ${this.productName} veröffentlichen möchtest?`;
          } else if (this.currentPlanningStatus === PLANNING_STATUS.WITHDRAWN) {
            question = `Bist du sicher, dass du das zurückgenommene ${this.productName} wiederveröffentlichen möchtest?`;
          }

          details = `Veröffentlichte ${this.productName}e können von Studierenden gebucht werden, sobald die Angebotseigenschaften eine Buchung ermöglichen.`;
          break;
        case PLANNING_STATUS.DRAFT:
          question = `Bist du sicher, dass du das ${this.productName} zurück in den Entwurfsstatus setzen möchtest?`;
          details = `${this.productName}e im Entwurfsstatus können nicht von Studierenden gebucht werden.`;
          break;
        case PLANNING_STATUS.WITHDRAWN:
          question = `Bist du sicher, dass du das ${this.productName} zurücknehmen möchtest?`;
          details = `Zurückgenommene ${this.productName}e können von Studierenden nicht mehr gebucht werden.`;
          ctaText = 'Zurücknehmen';
          break;
      }
      return { question, details, ctaText };
    },
    ctaStyle() {
      return this.requestedPlanningStatus === PLANNING_STATUS.WITHDRAWN
        ? 'bg-status-red'
        : '';
    },
    isRequestedPlanningStatusWithdraw() {
      return this.requestedPlanningStatus === PLANNING_STATUS.WITHDRAWN;
    },
  },
  methods: {
    onTransitionConfirmed() {
      switch (this.requestedPlanningStatus) {
        case PLANNING_STATUS.PUBLISHED:
          if (this.currentPlanningStatus === PLANNING_STATUS.WITHDRAWN) {
            this.$emit('republish');
          } else {
            this.$emit('publish');
          }
          break;
        case PLANNING_STATUS.DRAFT:
          this.$emit('unpublish');
          break;
        case PLANNING_STATUS.WITHDRAWN:
          this.$emit('withdraw');
          break;
      }
      this.$emit('close');
    },
  },
};
</script>
