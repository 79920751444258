<template>
  <n-applet-wrapper id="epos-product-offer">
    <router-view />
  </n-applet-wrapper>
</template>

<script>
import { MIXINS } from '@/mixins';
import { NAppletWrapper } from '@careerpartner/nitro';

export default {
  name: 'AppProductOffer',
  components: { NAppletWrapper },
  mixins: [MIXINS],
};
</script>
