<template>
  <n-tooltip :content="tooltip">
    <n-icon :class="classes" :icon="icon" />
  </n-tooltip>
</template>

<script>
import { NIcon, NTooltip } from '@careerpartner/nitro';
import { MIXINS } from '@/mixins';

export default {
  name: 'EnrollmentStateIcon',
  components: {
    NIcon,
    NTooltip,
  },
  mixins: [MIXINS],

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  computed: {
    isChecked() {
      return this.enrollmentStateFormatter(this.value);
    },

    icon() {
      return this.isChecked ? 'mdi-check' : 'mdi-close';
    },

    classes() {
      return this.isChecked ? 'text-green-600' : 'text-gray-700';
    },

    tooltip() {
      return this.isChecked
        ? 'Einschreibung möglich'
        : 'Einschreibung nicht möglich';
    },
  },
};
</script>
