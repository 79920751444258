<template>
  <img :src="analystImage" alt="Analyst" />
</template>
<script>
import { IMAGE_URLS } from '@/constants';

export default {
  name: 'AnalystImage',
  computed: {
    analystImage() {
      return IMAGE_URLS.ANALYST;
    },
  },
};
</script>
