import { startOfDay } from 'date-fns';
export const setStartDate = (date) => {
  const today = new Date();

  const isToday =
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();

  if (isToday) {
    date.setHours(today.getHours());
    date.setMinutes(today.getMinutes());
    date.setSeconds(today.getSeconds());

    return date;
  }

  return startOfDay(date);
};
