export default () => ({
  loading: false,
  courseOffer: null,
  academicStaffAssignments: [],
  academicStaffProfiles: [],
  isLoadingAcademicStaffAssignments: false,
  invalidAcademicStaffAssignments: [],
  isLoadingInvalidAcademicStaffAssignments: false,
  courseOfferEditable: false,
});
