import { setStartDate } from '@/utils/setStartDate';
import { endOfDay, format } from 'date-fns';

export const mapCourseOfferToView = (courseOffer) => {
  return {
    ...courseOffer,
    year: courseOffer.year && `${courseOffer.year}`,
    startDate:
      courseOffer.enrollmentStartDate &&
      format(new Date(courseOffer.enrollmentStartDate), 'yyyy-MM-dd'),
    endDate:
      courseOffer.enrollmentEndDate &&
      format(new Date(courseOffer.enrollmentEndDate), 'yyyy-MM-dd'),
  };
};

export const mapCourseOfferToAPI = (formData, assignedCourse) => {
  return {
    ...formData,
    courseId: assignedCourse.courseId,
    careCourseVersionId: assignedCourse.externalCareId,
    courseV2Id: assignedCourse.courseV2Id,
    courseMainVersionId: assignedCourse.courseMainVersionId,
    year: formData.year && Number(formData.year),
    enrollmentStartDate: setStartDate(new Date(formData.startDate)),
    maxParticipants:
      formData.maxParticipants && Number(formData.maxParticipants),
    enrollmentEndDate:
      formData.endDate &&
      new Date(endOfDay(new Date(formData.endDate)).setMilliseconds(0)),
  };
};
