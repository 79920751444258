<template>
  <div>
    <span
      v-if="!forceOverflow"
      class="text-xs font-semibold"
      :class="[textColor]"
    >
      {{ value }}%
    </span>
    <div class="w-full bg-blue-200 rounded-full h-1.5">
      <div
        class="h-1.5 rounded-full"
        :class="[backgroundColor]"
        :style="{ width: `${fixedPercentage}%` }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',

  props: {
    value: {
      type: Number,
      required: true,
    },
    forceOverflow: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  computed: {
    fixedPercentage() {
      if (this.forceOverflow) {
        return 100;
      }

      if (this.value <= 0) {
        return 0;
      }

      if (this.value >= 100) {
        return 100;
      }

      return this.value;
    },

    colorCode() {
      if (this.value > 100) {
        return 1;
      }

      if (this.forceOverflow || this.value === 100) {
        return 2;
      }

      return 0;
    },

    textColor() {
      switch (this.colorCode) {
        case 1:
          return 'text-error';
        case 2:
          return 'text-orange-600';
        default:
          return 'text-primary';
      }
    },

    backgroundColor() {
      switch (this.colorCode) {
        case 1:
          return 'bg-error';
        case 2:
          return 'bg-orange-600';
        default:
          return 'bg-primary';
      }
    },
  },
};
</script>
