import { ProductOfferApi } from '@/services/product-offer-api';
import { router } from '@/router';

export default {
  async loadModuleOffer({ commit }, moduleOfferId) {
    if (!moduleOfferId) return;

    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);

    try {
      const response = await ProductOfferApi.getModuleOffer(moduleOfferId);
      commit('setModuleOffer', response.data);
    } finally {
      commit('setLoading', false);
    }
  },

  async loadModuleOfferEditability({ commit }, moduleOfferId) {
    if (!moduleOfferId) return;

    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);

    try {
      const response = await ProductOfferApi.getModuleOfferEditability(
        moduleOfferId
      );

      commit('setModuleOfferEditability', response?.data?.value || false);
    } finally {
      commit('setLoading', false);
    }
  },

  async updateModuleOffer({ commit, dispatch }, moduleOffer) {
    if (!moduleOffer) return;

    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);

    try {
      const response = await ProductOfferApi.updateModuleOffer(moduleOffer);

      await router.push({
        name: 'module-offer-detail',
        params: { moduleOfferId: response.id },
      });

      dispatch(
        'snackbar/updateSnackbar',
        {
          message: 'Modulangebot wurde erfolgreich aktualisiert',
        },
        { root: true }
      );
    } finally {
      commit('setLoading', false);
    }
  },
  async deleteModuleOffer({ commit, dispatch }, moduleOfferId) {
    if (!moduleOfferId) return;

    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);

    try {
      await ProductOfferApi.deleteModuleOffer(moduleOfferId);

      await router.push({
        name: 'module-offer-list',
      });

      dispatch(
        'snackbar/updateSnackbar',
        {
          message: 'Modulangebot wurde erfolgreich gelöscht',
        },
        { root: true }
      );
    } finally {
      commit('setLoading', false);
    }
  },
  async createModuleOffer({ commit, dispatch }, moduleOffer) {
    if (!moduleOffer) return;

    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);

    try {
      const response = await ProductOfferApi.createModuleOffer(moduleOffer);

      await router.push({
        name: 'module-offer-detail',
        params: { moduleOfferId: response.id },
      });

      dispatch(
        'snackbar/updateSnackbar',
        {
          message: 'Modulangebot wurde erfolgreich gespeichert',
        },
        { root: true }
      );
    } finally {
      commit('setLoading', false);
    }
  },

  async getPossibleTransitions({ commit }, moduleOfferId) {
    if (!moduleOfferId) return;
    let response;

    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);

    try {
      response = await ProductOfferApi.getModuleOfferPossibleTransitions(
        moduleOfferId
      );
    } finally {
      commit('setLoading', false);
    }
    return response;
  },

  async publishModuleOffer({ commit, dispatch }, moduleOfferId) {
    if (!moduleOfferId) return;

    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);

    try {
      await ProductOfferApi.publishModuleOffer(moduleOfferId);
      dispatch(
        'snackbar/updateSnackbar',
        {
          message: 'Modulangebot erfolgreich veröffentlicht.',
        },
        { root: true }
      );
    } finally {
      commit('setLoading', false);
    }
  },

  async republishModuleOffer({ commit, dispatch }, moduleOfferId) {
    if (!moduleOfferId) return;

    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);

    try {
      await ProductOfferApi.republishModuleOffer(moduleOfferId);
      dispatch(
        'snackbar/updateSnackbar',
        {
          message:
            'Modulangebot erfolgreich veröffentlicht. Modulangebot kann ab sofort von Studierenden gebucht werden.',
        },
        { root: true }
      );
    } finally {
      commit('setLoading', false);
    }
  },

  async unpublishModuleOffer({ commit, dispatch }, moduleOfferId) {
    if (!moduleOfferId) return;

    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);

    try {
      await ProductOfferApi.unpublishModuleOffer(moduleOfferId);
      dispatch(
        'snackbar/updateSnackbar',
        {
          message:
            'Modulangebot erfolgreich als Entwurf zurückgesetzt. Modulangebot kann ab sofort nicht mehr von Studierenden gebucht werden.',
        },
        { root: true }
      );
    } finally {
      commit('setLoading', false);
    }
  },

  async withdrawModuleOffer({ commit, dispatch }, moduleOfferId) {
    if (!moduleOfferId) return;

    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);

    try {
      await ProductOfferApi.withdrawModuleOffer(moduleOfferId);
      dispatch(
        'snackbar/updateSnackbar',
        {
          message:
            'Modulangebot erfolgreich zurückgenommen. Modulangebot kann ab sofort nicht mehr von Studierenden gebucht werden.',
        },
        { root: true }
      );
    } finally {
      commit('setLoading', false);
    }
  },
};
