<template>
  <div
    class="py-8 flex flex-col justify-center items-center text-center bg-blue-100 border border-gray-700 rounded border-dashed overflow-hidden"
  >
    <p class="text-black font-bold mb-1">
      Bisher wurde kein {{ type }} ausgewählt.
    </p>
    <p class="text-gray-700 text-xs">
      Bitte ein {{ type }} auswählen, für das ein {{ type + 'angebot' }}
      erstellt werden soll.
    </p>
    <n-button variant="tertiary" class="mt-2" @click="onButtonClick">
      Durchsuchen
    </n-button>
  </div>
</template>

<script>
import { NButton } from '@careerpartner/nitro';

export default {
  name: 'NoProductAssignedCard',

  components: {
    NButton,
  },
  props: {
    type: {
      type: String,
      default: 'Fach',
    },
  },
  emits: ['openAssignmentClicked'],

  methods: {
    onButtonClick() {
      this.$emit('openAssignmentClicked');
    },
  },
};
</script>
