import { sortOrder } from '@/constants/sortOrder';

export const determineCurrentSortOrder = (
  currentSortOrder,
  previousSortOrder
) => {
  if (
    currentSortOrder.key === previousSortOrder.key &&
    currentSortOrder.order === previousSortOrder.order
  ) {
    return;
  }

  if (currentSortOrder.key !== previousSortOrder.key) {
    currentSortOrder = { ...currentSortOrder, order: sortOrder.ASC };
  }

  return currentSortOrder;
};
