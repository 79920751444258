import { OFFER_ORIGIN } from '@/constants/offerOrigin';
import { PLANNING_STATUS } from '@/constants/planning-status';

export const MIXINS = {
  computed: {
    yearSectionOptions() {
      return [
        {
          value: 'WINTER_SEMESTER',
          label: this.yearSectionFormatter('WINTER_SEMESTER'),
        },
        {
          value: 'SUMMER_SEMESTER',
          label: this.yearSectionFormatter('SUMMER_SEMESTER'),
        },
        {
          value: 'FIRST_QUARTER',
          label: this.yearSectionFormatter('FIRST_QUARTER'),
        },
        {
          value: 'SECOND_QUARTER',
          label: this.yearSectionFormatter('SECOND_QUARTER'),
        },
        {
          value: 'THIRD_QUARTER',
          label: this.yearSectionFormatter('THIRD_QUARTER'),
        },
        {
          value: 'FOURTH_QUARTER',
          label: this.yearSectionFormatter('FOURTH_QUARTER'),
        },
      ];
    },
  },
  methods: {
    originFormatter({ origin, isSyncedWithCare }) {
      if (origin === OFFER_ORIGIN.EPOS) {
        return 'Epos';
      } else if (origin === OFFER_ORIGIN.CARE && isSyncedWithCare === false) {
        return 'Care (desynchronisiert)';
      } else if (origin === OFFER_ORIGIN.PRODUCT) {
        return 'IU Product Catalog';
      }

      return 'Care (synchronisiert)';
    },
    yearSectionWithYearFormatter({ year, yearSection }) {
      if (!yearSection && !year) {
        return 'Permanent';
      }

      const yearSectionShortcut = this.yearSectionFormatter(yearSection);
      const yearSectionDigits = year.toString();

      return `${yearSectionShortcut}/${yearSectionDigits}`;
    },
    shortDate(date) {
      if (!date) {
        return '-';
      }

      return new Date(date).toLocaleDateString('de', {});
    },
    teachingFormatFormatter(value) {
      switch (value) {
        case 'PRAESENZ-VORLESUNGEN':
          return 'Präsenz Vorlesung';
        case 'ONLINE-SELBSTSTUDIUM':
          return 'Online Selbststudium';
        case 'THESIS':
          return 'Thesis';
        case 'PRAXIS-PROJEKT':
          return 'Praxisprojekt';
        case 'INTEGRIERTE-VORLESUNG':
          return 'Integrierte Vorlesung';
        case 'PRAXIS-REFLEKTION':
          return 'Praxisreflektion';
        case 'INTEGRIERTE-UEBUNG':
          return 'Integrierte Übung';
        case 'SEMINAR':
          return 'Seminar';
        case 'PRAXIS-SEMINAR':
          return 'Praxisseminar';
        case 'FACHCOACHING':
          return 'Fachcoaching';
        case 'TUTORIUM':
          return 'Tutorium';
        default:
          return '';
      }
    },
    academicStaffAssignmentRoleFormatter(value) {
      switch (value) {
        case 'TUTOR_FS':
          return 'Tutor';
        case 'CO_CORRECTOR_EXAMINATION_TOOLS':
          return 'Co-Korrektor (Prüfungstools)';
        case 'MODULE_OWNER_DISTANCE_LEARNING':
          return 'Modulverantwortlicher FS';
        default:
          return '';
      }
    },
    planningStatusFormatter(value) {
      switch (value) {
        case PLANNING_STATUS.DRAFT:
          return 'Entwurf';
        case PLANNING_STATUS.PUBLISHED:
          return 'Veröffentlicht';
        case PLANNING_STATUS.WITHDRAWN:
          return 'Zurückgenommen';
        case PLANNING_STATUS.UNKNOWN:
          return 'Unbekannt';
        default:
          return '';
      }
    },
    planningStatusActionTextFormatter(currentStatus, possibleStatus) {
      switch (possibleStatus) {
        case PLANNING_STATUS.DRAFT:
          return 'in Entwurf zurücksetzen';
        case PLANNING_STATUS.PUBLISHED:
          return currentStatus === PLANNING_STATUS.WITHDRAWN
            ? 'wiederveröffentlichen'
            : 'veröffentlichen';
        case PLANNING_STATUS.WITHDRAWN:
          return 'Angebot zurücknehmen';
        default:
          return '';
      }
    },
    enrollmentStateFormatter(value) {
      return value === 'ENROLLMENT_STARTED';
    },
    yearSectionFormatter(yearSection) {
      switch (yearSection) {
        case 'WINTER_SEMESTER':
          return 'WiSe';
        case 'SUMMER_SEMESTER':
          return 'SoSe';
        case 'FIRST_QUARTER':
          return 'Q1';
        case 'SECOND_QUARTER':
          return 'Q2';
        case 'THIRD_QUARTER':
          return 'Q3';
        case 'FOURTH_QUARTER':
          return 'Q4';
        default:
          return '-';
      }
    },
  },
};
