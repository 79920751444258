export const mapModuleOfferToView = (moduleOffer) => {
  return {
    ...moduleOffer,
    year: moduleOffer.year && `${moduleOffer.year}`,
  };
};

export const mapModuleOfferToAPI = (formData, assignedModule) => {
  return {
    ...formData,
    moduleId: assignedModule.moduleId,
    careModuleVersionId: assignedModule.externalCareId,
    moduleV2Id: assignedModule.moduleV2Id,
    moduleMainVersionId: assignedModule.moduleMainVersionId,
    year: formData.year && Number(formData.year),
  };
};
