<template>
  <view-wrapper>
    <template #headline>
      <p>Neues Fachangebot anlegen</p>
    </template>
    <validation-form
      v-slot="{ meta }"
      ref="form"
      :initial-values="initialValues"
    >
      <n-toolbar>
        <n-toolbar-button
          text="Als Entwurf anlegen"
          :disabled="!meta.valid || !hasChanged || !assignedCourse"
          icon="mdi-content-save-outline"
          @click="saveAsDraft"
        />
      </n-toolbar>

      <offer-form-layout-wrapper
        offer-type-label="Fachangebot"
        assignment-selection-headline="Auswahl eines Fachs*"
      >
        <template #assignment-selection>
          <course-offer-course-assignment
            :assigned-course="assignedCourse"
            @course-selected="setAssignedCourse"
          />
        </template>

        <template #form>
          <OfferInformationForm
            v-model="offerFormData"
            @has-changed="(changed) => (hasChanged = changed)"
          />
        </template>
      </offer-form-layout-wrapper>
    </validation-form>
  </view-wrapper>
</template>

<script>
import { NToolbar, NToolbarButton } from '@careerpartner/nitro';
import ViewWrapper from '@/components/ViewWrapper';
import CourseOfferCourseAssignment from '@/components/CourseOfferCourseAssignment';
import { mapActions, mapState } from 'vuex';
import OfferInformationForm from '@/components/CourseOfferInformationForm';
import { mapCourseOfferToAPI } from '@/utils/mapCourseOffer';
import OfferFormLayoutWrapper from '@/components/OfferFormLayoutWrapper';

export default {
  name: 'CourseOfferCreateView',

  components: {
    OfferInformationForm,
    NToolbar,
    NToolbarButton,
    ViewWrapper,
    CourseOfferCourseAssignment,
    OfferFormLayoutWrapper,
  },

  data: () => ({
    assignedCourse: null,
    name: '',
    year: null,
    yearSection: null,
    teachingFormat: null,
    startDate: null,
    endDate: null,
    maxParticipants: null,
    initialValues: {
      name: null,
      year: null,
      yearSection: null,
      teachingFormat: null,
      startDate: null,
      endDate: null,
      maxParticipants: null,
    },
    hasChanged: false,
  }),
  computed: {
    offerFormData: {
      get() {
        return {
          name: this.name.trim(),
          year: this.year,
          yearSection: this.yearSection,
          teachingFormat: this.teachingFormat,
          startDate: this.startDate,
          endDate: this.endDate,
          maxParticipants: this.maxParticipants,
        };
      },
      set(value) {
        this.name = value.name.trim();
        this.year = value.year;
        this.yearSection = value.yearSection;
        this.teachingFormat = value.teachingFormat;
        this.startDate = value.startDate;
        this.endDate = value.endDate;
        this.maxParticipants = value.maxParticipants;
      },
    },
    ...mapState('courseOffer', ['courseOffer']),
  },

  watch: {
    yearSectionEnabled(currentVal) {
      if (!currentVal) {
        this.year = null;
        this.yearSection = null;
      }
    },

    endDateEnabled(currentVal) {
      if (!currentVal) {
        this.endDate = null;
      }
    },

    attendanceNumberEnabled(currentVal) {
      if (!currentVal) {
        this.maxParticipants = null;
      }
    },
  },

  methods: {
    async saveAsDraft() {
      const mappedValue = mapCourseOfferToAPI(
        this.offerFormData,
        this.assignedCourse
      );

      await this.createCourseOffer(mappedValue);
    },
    setAssignedCourse(assignedCourse) {
      this.assignedCourse = assignedCourse;
    },
    ...mapActions('courseOffer', ['createCourseOffer']),
    ...mapActions('snackbar', ['updateSnackbar']),
  },
};
</script>
