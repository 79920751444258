<template>
  <n-dialog
    :is-open="isModalOpen"
    :subject="dialogQuestion"
    :description="dialogDetails"
    @close="closeModal"
  >
    <template #image>
      <img :src="trashWarningIcon" alt="delete offer icon" />
    </template>

    <template #actions>
      <div class="w-full flex justify-around mt-10">
        <n-button variant="secondary" @click="closeModal">Abbrechen</n-button>
        <n-button variant="primary" @click="deleteOffer">Löschen</n-button>
      </div>
    </template>
  </n-dialog>
</template>

<script>
import { IMAGE_URLS } from '@/constants';

export default {
  name: 'DeleteWarningModal',

  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'deleteConfirmed'],

  computed: {
    trashWarningIcon() {
      return IMAGE_URLS.TRASH;
    },
    dialogQuestion() {
      return 'Bist du sicher, dass du dieses Angebot löschen möchtest?';
    },
    dialogDetails() {
      return 'Angebote werden archiviert und können NICHT wiederhergestellt werden.';
    },
  },

  methods: {
    closeModal() {
      this.$emit('close');
    },

    deleteOffer() {
      this.$emit('deleteConfirmed');
      this.closeModal();
    },
  },
};
</script>
