<template>
  <validation-provider
    v-slot="{ errors, field }"
    name="name"
    label="Name des Fachangebots"
    rules="required|max:255|min:3"
  >
    <n-input
      id="course-offer-name"
      v-model="offerFormData.name"
      class="col-span-full"
      v-bind="field"
      name="name"
      label="Name des Fachangebots"
      required
      :errors="errors"
      @update:model-value="removeLeadingWhiteSpace($event)"
    />
  </validation-provider>

  <div class="col-span-full">
    <div>Angebotszeitraum*</div>
    <div class="flex justify-between mt-2">
      <n-radio-group
        :options="YEAR_SECTION.options"
        name="year-section"
        class="w-1/4"
      >
        <template #default="{ options }">
          <div class="w-full flex justify-between items-center">
            <div v-for="(item, index) in options" :key="index">
              <n-radio-button v-model="yearSectionEnabled" :value="item.value">
                {{ item.label }}
              </n-radio-button>
            </div>
          </div>
        </template>
      </n-radio-group>
      <div class="w-1/3">
        <n-select
          v-if="!yearSectionEnabled"
          id="course-offer-year"
          v-model="offerFormData.year"
          label="Jahr"
          name="year"
          disabled
          :options="yearsOptions"
          option-label="label"
          track-by="value"
          mapped
        />
        <validation-provider
          v-else
          v-slot="{ errors, field }"
          name="year"
          label="Jahr"
          rules="required"
        >
          <n-select
            id="course-offer-year"
            v-model="offerFormData.year"
            label="Jahr"
            name="year"
            :errors="errors"
            v-bind="field"
            :disabled="!yearSectionEnabled"
            :options="yearsOptions"
            option-label="label"
            track-by="value"
            mapped
            required
          />
        </validation-provider>
      </div>
      <div class="w-1/3">
        <n-select
          v-if="!yearSectionEnabled"
          id="course-offer-year-section"
          v-model="offerFormData.yearSection"
          class="grow"
          label="Semester / Quartal"
          name="yearSection"
          option-label="label"
          track-by="value"
          :options="yearSectionOptions"
          disabled
        />
        <validation-provider
          v-else
          v-slot="{ errors, field }"
          name="yearSection"
          label="Semester / Quartal"
          rules="required"
        >
          <n-select
            id="course-offer-year-section"
            v-model="offerFormData.yearSection"
            class="grow"
            :errors="errors"
            v-bind="field"
            label="Semester / Quartal"
            name="yearSection"
            option-label="label"
            track-by="value"
            :options="yearSectionOptions"
            required
          />
        </validation-provider>
      </div>
    </div>
  </div>

  <validation-provider
    v-slot="{ errors, field }"
    name="teachingFormat"
    label="Lehrform"
    rules="required"
  >
    <n-select
      id="course-offer-teaching-format"
      v-model="offerFormData.teachingFormat"
      class="col-span-full"
      v-bind="field"
      label="Lehrform"
      name="teachingFormat"
      option-label="label"
      track-by="value"
      mapped
      required
      :options="teachingFormatOptions"
      :errors="errors"
    />
  </validation-provider>
  <validation-provider
    v-slot="{ errors, field }"
    name="startDate"
    label="Startdatum"
    rules="required"
  >
    <n-input
      id="course-offer-start-date"
      v-model="offerFormData.startDate"
      name="startDate"
      label="Startdatum"
      type="date"
      :errors="errors"
      v-bind="field"
      :min="todayDate"
      required
      class="col-span-6"
    />
  </validation-provider>
  <div class="col-span-full">
    <div>Enddatum*</div>
    <div class="flex mt-2">
      <n-radio-group
        :options="COURSE_OFFER_DATETYPE.options"
        name="endDateRadio"
        class="w-2/3 mr-5"
        :disabled="isEndDateSectionDisabled"
      >
        <template #default="{ options }">
          <div class="flex w-full justify-between">
            <div v-for="(item, index) in options" :key="index">
              <n-radio-button v-model="endDateEnabled" :value="item.value">
                {{ item.label }}
              </n-radio-button>
            </div>
          </div>
        </template>
      </n-radio-group>
      <n-input
        v-if="!endDateEnabled"
        id="course-offer-end-date"
        v-model="offerFormData.endDate"
        name="endDate"
        label="Enddatum"
        type="date"
        class="w-1/3"
        :disabled="isEndDateSectionDisabled || !endDateEnabled"
      />
      <validation-provider
        v-else
        v-slot="{ errors, field }"
        name="endDate"
        label="Enddatum"
        rules="required|after:@startDate"
      >
        <n-input
          id="course-offer-end-date"
          v-model="offerFormData.endDate"
          name="endDate"
          label="Enddatum"
          type="date"
          :errors="errors"
          v-bind="field"
          class="w-1/3"
          :required="endDateEnabled"
          :disabled="isEndDateSectionDisabled || !endDateEnabled"
          :min="getEndDateMinimumDate"
        />
      </validation-provider>
    </div>
  </div>

  <div class="col-span-full">
    <div>Teilnehmerzahl*</div>
    <div class="flex mt-2">
      <n-radio-group
        class="w-2/3 mr-5"
        :options="ATTENDANCE_NUMBER_TYPE.options"
        name="attendance"
      >
        <template #default="{ options }">
          <div class="flex w-full justify-between">
            <div v-for="(item, index) in options" :key="index">
              <n-radio-button
                v-model="attendanceNumberEnabled"
                :value="item.value"
              >
                {{ item.label }}
              </n-radio-button>
            </div>
          </div>
        </template>
      </n-radio-group>
      <n-input
        v-if="!attendanceNumberEnabled"
        id="course-offer-max-participants"
        v-model="offerFormData.maxParticipants"
        class="w-1/3"
        name="maxParticipants"
        type="number"
        label="Max. Teilnehmerzahl"
        disabled
      />
      <validation-provider
        v-else
        v-slot="{ errors, field }"
        name="maxParticipants"
        label="Maximale Teilnehmerzahl"
        rules="min_value:1|required"
      >
        <n-input
          id="course-offer-max-participants"
          v-model="offerFormData.maxParticipants"
          class="w-1/3"
          v-bind="field"
          name="maxParticipants"
          type="number"
          label="Max. Teilnehmerzahl"
          :errors="errors"
          required
        />
      </validation-provider>
    </div>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual';
import { NInput, NSelect, NRadioGroup } from '@careerpartner/nitro';
import {
  endDateInputDisabled,
  startDateInputDisabled,
} from '@/utils/date-input-disabled';
import { MIXINS } from '@/mixins';

const COURSE_OFFER_DATETYPE = Object.freeze({
  options: [
    { value: false, label: 'unbegrenzt' },
    { value: true, label: 'begrenzt' },
  ],
});

const ATTENDANCE_NUMBER_TYPE = Object.freeze({
  options: [
    { value: false, label: 'unbegrenzt' },
    { value: true, label: 'begrenzt' },
  ],
});

const YEAR_SECTION = Object.freeze({
  options: [
    { value: false, label: 'permanent' },
    { value: true, label: '' },
  ],
});

export default {
  name: 'CourseOfferInformationForm',
  components: {
    NInput,
    NSelect,
    NRadioGroup,
  },
  mixins: [MIXINS],
  props: {
    modelValue: {
      type: Object,
      default: () => ({
        endDate: null,
        maxParticipants: null,
        name: null,
        startDate: null,
        teachingFormat: null,
        year: null,
        yearSection: null,
      }),
    },
  },
  emits: ['update:model-value', 'hasChanged'],
  data() {
    return {
      offerFormData: this.modelValue,
      COURSE_OFFER_DATETYPE,
      ATTENDANCE_NUMBER_TYPE,
      YEAR_SECTION,
      attendanceNumberEnabled: !!this.modelValue.maxParticipants,
      todayDate: new Date().toISOString().split('T')[0],
      todayDateString: new Date().toISOString().slice(0, 10),
      teachingFormatOptions: [
        {
          value: 'PRAESENZ-VORLESUNGEN',
          label: this.teachingFormatFormatter('PRAESENZ-VORLESUNGEN'),
        },
        {
          value: 'ONLINE-SELBSTSTUDIUM',
          label: this.teachingFormatFormatter('ONLINE-SELBSTSTUDIUM'),
        },
        {
          value: 'THESIS',
          label: this.teachingFormatFormatter('THESIS'),
        },
        {
          value: 'PRAXIS-PROJEKT',
          label: this.teachingFormatFormatter('PRAXIS-PROJEKT'),
        },
        {
          value: 'INTEGRIERTE-VORLESUNG',
          label: this.teachingFormatFormatter('INTEGRIERTE-VORLESUNG'),
        },
        {
          value: 'PRAXIS-REFLEKTION',
          label: this.teachingFormatFormatter('PRAXIS-REFLEKTION'),
        },
        {
          value: 'INTEGRIERTE-UEBUNG',
          label: this.teachingFormatFormatter('INTEGRIERTE-UEBUNG'),
        },
        {
          value: 'SEMINAR',
          label: this.teachingFormatFormatter('SEMINAR'),
        },
        {
          value: 'PRAXIS-SEMINAR',
          label: this.teachingFormatFormatter('PRAXIS-SEMINAR'),
        },
        {
          value: 'TUTORIUM',
          label: this.teachingFormatFormatter('TUTORIUM'),
        },
      ],
      lastEndDateValue: this.modelValue.endDate,
      previousStartDate: this.modelValue.startDate,
      yearSectionEnabled: !!this.modelValue.yearSection,
      endDateEnabled: !!this.modelValue.endDate,
      initialValue: {},
      currentValue: {},
    };
  },
  computed: {
    isEndDateSectionDisabled() {
      return (
        !!this.modelValue.id &&
        this.offerFormData.endDate &&
        endDateInputDisabled(new Date(this.offerFormData.endDate))
      );
    },
    isStartDateDisabled() {
      return (
        !!this.modelValue.id &&
        this.offerFormData.startDate &&
        startDateInputDisabled(new Date(this.previousStartDate).getTime())
      );
    },
    getEndDateMinimumDate() {
      return this.offerFormData.startDate
        ? new Date(this.offerFormData.startDate).toISOString().split('T')[0]
        : this.todayDate;
    },
    yearsOptions() {
      const yearMinDate = (new Date().getFullYear() - 10).toString();
      const yearMaxDate = (new Date().getFullYear() + 10).toString();
      const options = [];
      let currentYear = new Date(yearMinDate).getFullYear();

      while (currentYear <= new Date(yearMaxDate).getFullYear()) {
        options.push(currentYear.toString());
        currentYear += 1;
      }

      return options.map((opt) => {
        return { label: opt, value: opt };
      });
    },
  },
  watch: {
    yearSectionEnabled(currentVal) {
      this.currentValue.yearSectionEnabled = currentVal;
      if (!currentVal) {
        this.offerFormData = {
          ...this.offerFormData,
          year: null,
          yearSection: null,
        };
      }
    },

    endDateEnabled(currentVal) {
      this.currentValue.endDateEnabled = currentVal;
      if (!currentVal) {
        this.offerFormData = {
          ...this.offerFormData,
          endDate: null,
        };
      } else {
        if (this.lastEndDateValue) {
          this.offerFormData = {
            ...this.offerFormData,
            endDate: this.lastEndDateValue,
          };
        }
      }
    },

    attendanceNumberEnabled(currentVal) {
      this.currentValue.attendanceNumberEnabled = currentVal;
      if (!currentVal) {
        this.offerFormData = {
          ...this.offerFormData,
          maxParticipants: null,
        };
      }
    },
    offerFormData: {
      handler: function (newVal) {
        this.currentValue = { ...this.currentValue, ...newVal };
        this.$emit('update:model-value', newVal);
      },
      deep: true,
    },
    currentValue: {
      deep: true,
      handler(internalValue) {
        this.$emit('hasChanged', !isEqual(internalValue, this.initialValue));
      },
    },
  },
  mounted() {
    this.initialValue = _.cloneDeep({
      ...this.offerFormData,
      attendanceNumberEnabled: !!this.attendanceNumberEnabled,
      yearSectionEnabled: !!this.yearSectionEnabled,
      endDateEnabled: !!this.endDateEnabled,
    });
    this.currentValue = _.cloneDeep(this.initialValue);
  },
  methods: {
    removeLeadingWhiteSpace(value) {
      this.offerFormData.name = value.trimStart();
    },
  },
};
</script>
