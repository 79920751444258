<template>
  <view-wrapper>
    <template #headline>
      <p>Neues Modulangebot anlegen</p>
    </template>
    <validation-form v-slot="{ meta }" :initial-values="initialValues">
      <n-toolbar>
        <n-toolbar-button
          text="Als Entwurf anlegen"
          icon="mdi-content-save-outline"
          :disabled="!meta.valid || !hasChanged || !assignedModule"
          @click="saveAsDraft"
        />
      </n-toolbar>

      <offer-form-layout-wrapper
        offer-type-label="Modulangebot"
        assignment-selection-headline="Auswahl eines Moduls*"
      >
        <template #assignment-selection>
          <module-offer-module-assignment
            :assigned-module="assignedModule"
            @module-selected="setAssignedModule"
          />
        </template>

        <template #form>
          <OfferInformationForm
            v-model="offerFormData"
            @has-changed="(changed) => (hasChanged = changed)"
          />
        </template>
      </offer-form-layout-wrapper>
    </validation-form>
  </view-wrapper>
</template>

<script>
import { NToolbar, NToolbarButton } from '@careerpartner/nitro';
import ViewWrapper from '@/components/ViewWrapper';
import ModuleOfferModuleAssignment from '@/components/ModuleOfferModuleAssignment';
import { mapActions, mapState } from 'vuex';
import OfferInformationForm from '@/components/ModuleOfferInformationForm';
import OfferFormLayoutWrapper from '@/components/OfferFormLayoutWrapper';
import { mapModuleOfferToAPI } from '@/utils/mapModuleOffer';

export default {
  name: 'ModuleOfferCreateView',

  components: {
    OfferInformationForm,
    NToolbar,
    NToolbarButton,
    ViewWrapper,
    ModuleOfferModuleAssignment,
    OfferFormLayoutWrapper,
  },
  data: () => ({
    assignedModule: null,
    name: '',
    year: null,
    yearSection: null,
    initialValues: {
      name: '',
      year: null,
      yearSection: null,
    },
    hasChanged: false,
  }),
  computed: {
    offerFormData: {
      get() {
        return {
          name: this.name.trim(),
          year: this.year,
          yearSection: this.yearSection,
        };
      },
      set(value) {
        this.name = value.name.trim();
        this.year = value.year;
        this.yearSection = value.yearSection;
      },
    },
    ...mapState('moduleOffer', ['moduleOffer']),
  },

  methods: {
    async saveAsDraft() {
      const mappedValue = mapModuleOfferToAPI(
        this.offerFormData,
        this.assignedModule
      );

      await this.createModuleOffer(mappedValue);
    },
    setAssignedModule(assignedModule) {
      this.assignedModule = assignedModule;
    },
    ...mapActions('moduleOffer', ['createModuleOffer']),
    ...mapActions('snackbar', ['updateSnackbar']),
  },
};
</script>
