import singleSpaVue from 'single-spa-vue';
import '@/config/set-public-path';
import { useRouter } from '@/router';
import { store } from '@/store';
import App from '@/App.vue';
import '@/assets/tailwind.css';
import { h, createApp } from 'vue';
import { useVeeValidate } from '@/plugins/veeValidate';
import { useNitro } from '@/plugins/nitro';
import { useDatadog } from '@/plugins/datadog';

const CONTAINER_SELECTOR = '#app-placeholder';

const vueLifecycles = singleSpaVue({
  createApp,
  replaceMode: true, //TODO needed?
  appOptions: {
    render() {
      // single-spa props are available on the "this" object. Forward them to your component as needed.
      // https://single-spa.js.org/docs/building-applications#lifecycle-props
      return h(App, {
        name: this.name,
      });
    },
    el: CONTAINER_SELECTOR,
  },
  handleInstance(app) {
    app.config.performance = true;
    app.use(store);
    useVeeValidate(app);
    useRouter(app);
    useNitro(app);
    useDatadog(app);
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;

export const devtools = {
  overlays: {
    selectors: [CONTAINER_SELECTOR],
  },
};
