export default () => ({
  isLoading: false,
  searchResult: [],
  pagination: {
    limit: 5,
    offset: 0,
    totalCount: 0,
  },
  filter: {
    search: '',
  },
});
