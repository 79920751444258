<template>
  <n-table :header-items="headerItems" :data="data" :is-loading="isLoading">
    <template #row="{ item }">
      <td class="y-6 flex items-center flex-wrap">
        <span class="w-full font-bold">{{ item.profileId }}</span>
        <p class="inline text-orange-600">
          <n-icon icon="alert" />
          Profil nicht gefunden. Bei Bedarf in CARE suchen.
        </p>
      </td>
    </template>
  </n-table>
</template>

<script>
import { NTable, NIcon } from '@careerpartner/nitro';

export default {
  name: 'EnhancedInvalidAcademicStaffAssignmentsTable',

  components: { NTable, NIcon },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    headerItems: [
      {
        class: 'px-3',
        label: 'CARE Profil-ID',
        key: 'profileId',
      },
    ],
  }),
};
</script>
