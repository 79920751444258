const truthyValues = ['true', 'yes', '1', 'on'];
const falsyValues = ['false', 'no', '0', 'off'];

export const booly = (env) => {
  if (!env) return undefined;
  env = String(env).toLowerCase().trim();
  if (truthyValues.includes(env)) return true;
  if (falsyValues.includes(env)) return false;
  return undefined;
};
