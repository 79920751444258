export default {
  hasFilters: (state) => Object.keys(state.filter).length > 0,

  getStatusFilter({ filter }) {
    return 'string' === typeof filter?.status ? filter.status.split(',') : [];
  },

  getFilter({ filter }, getters) {
    const status = getters.getStatusFilter;

    const transformedFilters = {};

    if (status.length > 0) {
      transformedFilters.status = status;
    }

    return {
      ...filter,
      ...transformedFilters,
    };
  },
};
