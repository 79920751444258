import '@/assets/base.css';
import '@/assets/tailwind.css';
import '@careerpartner/nitro/style.css';

import * as NitroComponents from '@careerpartner/nitro';
import { initialize } from '@careerpartner/nitro';

export const useNitro = (app) => {
  app.use(initialize);
  for (const componentName of Object.keys(NitroComponents)) {
    if (componentName.startsWith('N')) {
      app.component(componentName, NitroComponents[componentName]);
    }
  }
};
