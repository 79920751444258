<template>
  <n-modal
    :is-open="isModalOpen"
    :title="hasInitialValue ? 'Zeitraum bearbeiten' : 'Lehrende hinzufügen'"
    class="relative"
    @close="closeModal"
  >
    <validation-form
      ref="observer"
      v-slot="{ meta }"
      :key="validationFormKey"
      :initial-values="initialValues"
    >
      <div class="grid-flow-row grid grid-cols-12 gap-x-6 gap-y-7 p-5">
        <div class="col-span-full">
          <n-select
            v-if="hasInitialValue"
            id="academicStaffAssignmentModal-form__profile"
            v-model="internalValue.profileId"
            label="Name des/r Lehrenden"
            option-label="label"
            track-by="value"
            searchable
            mapped
            required
            :options="academicStaffProfiles"
            :disabled="hasInitialValue"
          />
          <validation-provider
            v-else
            v-slot="{ errors, field }"
            name="profileId"
            label="Name des/r Lehrenden"
            rules="required"
          >
            <n-select
              id="academicStaffAssignmentModal-form__profile"
              v-model="internalValue.profileId"
              v-bind="field"
              label="Name des/r Lehrenden"
              option-label="label"
              track-by="value"
              searchable
              mapped
              required
              :errors="errors"
              :options="academicStaffProfiles"
              :internal-search="false"
              @search-change="$emit('update:profileSearch', $event)"
            />
          </validation-provider>
        </div>

        <div class="col-span-full">
          <validation-provider
            v-slot="{ errors, field }"
            name="role"
            label="Rolle"
            rules="required"
          >
            <n-select
              id="academicStaffAssignmentModal-form__role"
              v-model="internalValue.role"
              v-bind="field"
              label="Rolle"
              option-label="label"
              track-by="value"
              searchable
              mapped
              required
              :errors="errors"
              :options="rolesOptions"
              :disabled="assignmentPeriodInThePast"
            />
          </validation-provider>
        </div>

        <div class="col-span-6">
          <validation-provider
            v-slot="{ errors, field }"
            name="assignmentStartDate"
            label="Startdatum"
            rules="required"
          >
            <n-input
              id="academicStaffAssignmentModal-form__startdate"
              v-model="internalValue.assignmentStartDate"
              name="assignmentStartDate"
              label="Startdatum"
              type="date"
              v-bind="field"
              :errors="errors"
              required
              :disabled="isStartDateDisabled"
              :min="todayDate"
            />
          </validation-provider>
        </div>

        <div class="col-span-full">
          <div>Enddatum*</div>
          <div class="flex flex-row mt-2">
            <n-radio-group
              v-model="dateRadioValue"
              class="w-1/2 mr-5"
              :options="ASSIGNMENT_DATE_TYPE.options"
              name="enddate"
              :disabled="isEndDateSectionDisabled"
            >
              <template #default="{ options }">
                <div class="w-full flex justify-between items-center">
                  <div v-for="(item, index) in options" :key="index">
                    <n-radio-button
                      v-model="dateRadioValue"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </n-radio-button>
                  </div>
                </div>
              </template>
            </n-radio-group>
            <n-input
              v-if="isEnddateDisabled"
              id="academicStaffAssignmentModal-form__enddate"
              v-model="internalValue.assignmentEndDate"
              name="assignmentEndDate"
              type="date"
              label="Enddatum"
              class="w-1/2"
              :min="getEndDateMinimumDate"
              disabled
            />
            <validation-provider
              v-else
              v-slot="{ errors, field }"
              name="assignmentEndDate"
              label="Enddatum"
              :rules="`required|after:${internalValue.assignmentStartDate}`"
            >
              <n-input
                id="academicStaffAssignmentModal-form__enddate"
                v-model="internalValue.assignmentEndDate"
                name="assignmentEndDate"
                v-bind="field"
                :errors="errors"
                type="date"
                label="Enddatum"
                class="w-1/2"
                :min="getEndDateMinimumDate"
                required
              />
            </validation-provider>
          </div>
        </div>

        <div v-if="roleChangeHint" class="col-span-full text-gray-700">
          <n-icon icon="alert" class="text-orange-600" />
          {{ roleChangeHint }}
        </div>

        <div class="col-span-full">
          <div class="text-caption text-gray-700">* Pflichtfelder</div>
        </div>
      </div>
      <div class="flex col-span-full place-content-end mt-7">
        <n-button
          class="absolute bottom-7 right-7"
          variant="primary"
          :disabled="!meta.valid || !hasChanged || isSaving"
          @click="save"
        >
          {{ hasInitialValue ? 'Speichern' : 'Hinzufügen' }}
        </n-button>
      </div>
    </validation-form>
  </n-modal>
</template>

<script>
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import {
  endDateInputDisabled,
  startDateInputDisabled,
} from '@/utils/date-input-disabled';
import { isNil, pick } from 'lodash';
import { MIXINS } from '@/mixins';

const ASSIGNMENT_DATE_TYPE = Object.freeze({
  options: [
    { value: '0', label: 'unbegrenzt' },
    { value: '1', label: 'begrenzt' },
  ],
});

const unlimited = ASSIGNMENT_DATE_TYPE.options[0].value;
const limited = ASSIGNMENT_DATE_TYPE.options[1].value;

export default {
  name: 'AcademicStaffAssignmentModal',

  mixins: [MIXINS],

  props: {
    academicStaffProfiles: {
      type: Array,
      default: () => [],
    },
    isSaving: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['save', 'update:profileSearch'],

  data() {
    return {
      ASSIGNMENT_DATE_TYPE,
      isModalOpen: false,
      hasInitialValue: false,
      limited,
      unlimited,
      dateRadioValue: unlimited,
      isEnddateDisabled: true,
      initialValue: {},
      internalValue: {},
      rolesOptions: [
        {
          value: 'TUTOR_FS',
          label: this.academicStaffAssignmentRoleFormatter('TUTOR_FS'),
        },
        {
          value: 'CO_CORRECTOR_EXAMINATION_TOOLS',
          label: this.academicStaffAssignmentRoleFormatter(
            'CO_CORRECTOR_EXAMINATION_TOOLS'
          ),
        },
        {
          value: 'MODULE_OWNER_DISTANCE_LEARNING',
          label: this.academicStaffAssignmentRoleFormatter(
            'MODULE_OWNER_DISTANCE_LEARNING'
          ),
        },
      ],
      todayDate: new Date().toISOString().split('T')[0],
      lastEndDateValue: null,
      roleChangeHint: null,
      initialValues: {
        profileId: null,
        role: null,
        assignmentStartDate: new Date().toISOString().slice(0, 10),
        assignmentEndDate: null,
      },
      validationFormKey: new Date().getTime(),
    };
  },

  computed: {
    hasChanged() {
      return !isEqual(this.internalValue, this.initialValue);
    },
    isEndDateSectionDisabled() {
      return (
        this.hasInitialValue &&
        this.initialValue.assignmentEndDate &&
        endDateInputDisabled(new Date(this.initialValue.assignmentEndDate))
      );
    },
    isStartDateDisabled() {
      return (
        this.hasInitialValue &&
        !isNil(this.initialValue.assignmentStartDate) &&
        startDateInputDisabled(
          new Date(this.initialValue.assignmentStartDate).getTime()
        )
      );
    },
    getEndDateMinimumDate() {
      return this.internalValue.assignmentStartDate
        ? new Date(this.internalValue.assignmentStartDate)
            .toISOString()
            .split('T')[0]
        : this.todayDate;
    },
    assignmentPeriodInThePast() {
      return this.isStartDateDisabled && this.isEndDateSectionDisabled;
    },
    roleChanged() {
      return (
        this.hasInitialValue &&
        this.internalValue.role !== this.initialValue.role
      );
    },
    assignmentDatesInTheFuture() {
      return !this.isStartDateDisabled && !this.isEndDateSectionDisabled;
    },
    startDateInPastAndEndDateInFuture() {
      return this.isStartDateDisabled && !this.isEndDateSectionDisabled;
    },
  },

  watch: {
    dateRadioValue(val) {
      switch (val) {
        case unlimited:
          this.isEnddateDisabled = true;
          if (this.hasInitialValue) {
            this.lastEndDateValue = this.initialValue.assignmentEndDate;
          }
          this.internalValue.assignmentEndDate = null;
          break;
        case limited:
          this.internalValue.assignmentEndDate = this.lastEndDateValue;
          this.isEnddateDisabled = false;
          break;
      }
    },
    internalValue() {
      this.validationFormKey = new Date().getTime();
    },
    roleChanged(changed) {
      if (changed) {
        this.applyDateChangesAfterRoleChange();
      } else {
        this.applyDateChangesAfterRoleReset();
      }
    },
  },

  methods: {
    openModal(val) {
      this.internalValue = {
        id: null,
        profileId: null,
        role: null,
        assignmentStartDate: new Date().toISOString().slice(0, 10),
        assignmentEndDate: null,
      };

      this.hasInitialValue = null !== val;

      Object.assign(
        this.internalValue,
        pick(val, Object.keys(this.internalValue))
      );

      this.dateRadioValue =
        null === this.internalValue.assignmentEndDate ? unlimited : limited;

      this.initialValue = cloneDeep(this.internalValue);

      if (this.hasInitialValue) {
        this.initialValues = Object.assign(
          this.initialValues,
          pick(this.initialValue, Object.keys(this.initialValues))
        );
        this.lastEndDateValue = this.initialValue.assignmentEndDate;
      }

      this.isModalOpen = true;
    },

    applyDateChangesAfterRoleChange() {
      if (this.assignmentDatesInTheFuture) {
        this.roleChangeHint =
          'Beim Speichern wird die bisherige Zuordnung der Lehrkraft gelöscht und durch eine neue Zuordnung mit der neu ausgewählten Rolle ersetzt.';
        return;
      }

      if (this.startDateInPastAndEndDateInFuture) {
        this.roleChangeHint =
          'Beim Speichern wird die bisherige Zuordnung der Lehrkraft mit dem heutigen Datum als Enddatum abgeschlossen und gleichzeitig eine neue Zuordnung mit der neu ausgewählten Rolle und dem heutigen Datum als Startdatum erstellt.';
        this.internalValue.assignmentStartDate = new Date()
          .toISOString()
          .slice(0, 10);
      }
    },

    applyDateChangesAfterRoleReset() {
      this.internalValue = { ...this.initialValue };
      this.roleChangeHint = null;
    },

    closeModal() {
      this.lastEndDateValue = null;
      this.isModalOpen = false;
    },

    save() {
      this.$emit('save', this.internalValue);
    },
  },
};
</script>
