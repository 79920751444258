import ModuleOfferListView from '@/views/module-offer/ModuleOfferListView.vue';
import ModuleOfferCreateView from '@/views/module-offer/ModuleOfferCreateView.vue';
import { FEATURE_FLAGS, SERVICE_ROUTE_PREFIX } from '@/constants';
import ModuleOfferUpdateView from '@/views/module-offer/ModuleOfferUpdateView.vue';
import ModuleOfferDetailView from '@/views/module-offer/ModuleOfferDetailView.vue';
import {
  beforeEnterFeatureGuard,
  beforeEnterPermissionGuard,
} from '@/utils/router-guards';
import { getDefaultSortOrder } from '@/constants/sortOrder';

const breadcrumbs = {
  moduleOfferList: {
    label: 'Modulangebote',
    to: {
      name: 'module-offer-list',
      query: {
        ...getDefaultSortOrder('moduleOffer'),
        status: 'KNOWN',
      },
    },
  },
  moduleOfferDetails: {
    label: 'Modulangebotsdetails',
    to: { name: 'module-offer-detail' },
  },
};

const searchMeta = {
  title: 'Modulangebot',
  headline: 'Suche',
  breadcrumbLinks: [breadcrumbs.moduleOfferList],
};

const offerBasicMeta = {
  title: 'Modulangebot',
  headline: 'Modulangebot',
};

const offerMeta = {
  ...offerBasicMeta,
  breadcrumbLinks: [breadcrumbs.moduleOfferList],
};

const offerDetailsMeta = {
  ...offerBasicMeta,
  breadcrumbLinks: [
    breadcrumbs.moduleOfferList,
    breadcrumbs.moduleOfferDetails,
  ],
};
export const moduleOfferRoutes = [
  {
    path: `${SERVICE_ROUTE_PREFIX}/module-offer`,
    name: 'module-offer-list',
    component: ModuleOfferListView,
    meta: searchMeta,
  },
  {
    path: `${SERVICE_ROUTE_PREFIX}/module-offer/new`,
    name: 'module-offer-create',
    component: ModuleOfferCreateView,
    meta: offerMeta,
    beforeEnter: (_to, _from, next) => {
      beforeEnterFeatureGuard(
        FEATURE_FLAGS.WRITE_UI_ENABLED,
        `${SERVICE_ROUTE_PREFIX}/module-offer`,
        next
      );
      beforeEnterPermissionGuard('module-offer', ['c'], next);
      next();
    },
  },
  {
    path: `${SERVICE_ROUTE_PREFIX}/module-offer/:moduleOfferId/update`,
    name: 'module-offer-update',
    component: ModuleOfferUpdateView,
    meta: offerMeta,
    beforeEnter: (_to, _from, next) => {
      beforeEnterFeatureGuard(
        FEATURE_FLAGS.WRITE_UI_ENABLED,
        `${SERVICE_ROUTE_PREFIX}/module-offer`,
        next
      );
      beforeEnterPermissionGuard('module-offer', ['u'], next);
      next();
    },
  },
  {
    path: `${SERVICE_ROUTE_PREFIX}/module-offer/:moduleOfferId`,
    name: 'module-offer-detail',
    component: ModuleOfferDetailView,
    meta: offerDetailsMeta,
    beforeEnter: (to, from, next) => {
      const moduleOfferList = to.meta.breadcrumbLinks[0].to;
      moduleOfferList.query = { ...moduleOfferList.query, ...from.query };
      next();
    },
  },
];
