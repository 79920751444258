<template>
  <div class="flex bg-blue-100 p-3 items-center">
    <div>
      <span class="font-bold text-gray-700">Filter</span>
    </div>
    <div class="flex-1 px-5">
      <span
        v-for="filter in mappedFilters"
        :key="filter.id"
        class="flex-0 mx-2 bg-cta px-2 text-white"
      >
        <span class="font-bold">{{ filter.type }}:</span>
        {{ filter.value }}
        <n-button variant="quaternary" @click="$emit('reset', filter.name)">
          <n-icon icon="mdi-close-box" class="text-white icon-md" />
        </n-button>
      </span>
    </div>
    <div>
      <n-button variant="tertiary" @click="$emit('reset')">
        Filter zurücksetzen
      </n-button>
    </div>
  </div>
</template>

<script>
import { NButton, NIcon } from '@careerpartner/nitro';
import { ENROLLMENT_STATUS } from '@/constants/enrollmentStatus';
import { OFFER_ORIGIN_FILTER } from '@/constants/offerOriginFilter';

export default {
  name: 'ListFilterBar',

  components: {
    NButton,
    NIcon,
  },

  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  emits: ['reset'],

  computed: {
    filterKeys() {
      return Object.keys(this.filters);
    },

    mappedFilters() {
      return this.filterKeys.reduce((accumulator, name, index) => {
        let value = this.filters[name];
        const isArray = Array.isArray(value);
        value = isArray
          ? value.map((v) => this.translatedValuesForFilter(name, v)).join('; ')
          : this.translatedValuesForFilter(name, value);

        accumulator.push({
          id: index,
          type: this.labelForFilterName(name),
          value,
          name,
        });

        return accumulator;
      }, []);
    },
  },

  methods: {
    labelForFilterName(name) {
      switch (name) {
        case 'search':
          return 'Suchen';
        case 'teachingFormat':
          return 'Fachangebot';
        case 'time':
          return 'Zeitraum';
        case 'status':
          return 'Status';
        case 'bookable':
          return 'Einschreibung';
        case 'enrollment':
          return 'Teilnehmer';
        case 'origin':
          return 'Herkunft';
        default:
          return '';
      }
    },

    translatedTeachingFormatValue(value) {
      switch (value) {
        case 'PRAESENZ-VORLESUNGEN':
          return 'Präsenz Vorlesung';
        case 'ONLINE-SELBSTSTUDIUM':
          return 'Online Selbststudium';
        case 'THESIS':
          return 'Thesis';
        case 'PRAXIS-PROJEKT':
          return 'Praxisprojekt';
        case 'INTEGRIERTE-VORLESUNG':
          return 'Integrierte Vorlesung';
        case 'PRAXIS-REFLEKTION':
          return 'Praxisreflektion';
        case 'INTEGRIERTE-UEBUNG':
          return 'Integrierte Übung';
        case 'SEMINAR':
          return 'Seminar';
        case 'PRAXIS-SEMINAR':
          return 'Praxisseminar';
        case 'FACHCOACHING':
          return 'Fachcoaching';
        case 'TUTORIUM':
          return 'Tutorium';
        default:
          return value;
      }
    },

    translatedTimeValue(value) {
      switch (value) {
        case 'true':
          return 'Nicht permanent';
        case 'false':
          return 'Permanent';
        default:
          return value;
      }
    },

    translatedStatusValue(value) {
      switch (value) {
        case 'DRAFT':
          return 'Entwurf';
        case 'WITHDRAWN':
          return 'Zurückgenommen';
        case 'PUBLISHED':
          return 'Veröffentlicht';
        case 'UNKNOWN':
          return 'Unbekannt';
        case 'KNOWN':
          return 'Nicht Unbekannt';
        default:
          return value;
      }
    },

    translatedBookableValue(value) {
      switch (value) {
        case 'true':
          return 'Buchbar';
        case 'false':
          return 'Nicht buchbar';
        default:
          return value;
      }
    },

    translateEnrollmentValue(value) {
      switch (value) {
        case ENROLLMENT_STATUS.NONE:
          return 'Keine Teilnehmer';
        case ENROLLMENT_STATUS.HAS_ENROLLMENTS:
          return 'Teilnehmer eingebucht';
        default:
          return value;
      }
    },

    translatedValuesForFilter(name, value) {
      switch (name) {
        case 'teachingFormat':
          return this.translatedTeachingFormatValue(value);
        case 'time':
          return this.translatedTimeValue(value);
        case 'status':
          return this.translatedStatusValue(value);
        case 'bookable':
          return this.translatedBookableValue(value);
        case 'enrollment':
          return this.translateEnrollmentValue(value);
        case 'origin':
          return this.translateOriginValue(value);
        default:
          return value;
      }
    },

    translateOriginValue(value) {
      switch (value) {
        case OFFER_ORIGIN_FILTER.EPOS:
          return value;
        case OFFER_ORIGIN_FILTER.PRODUCT:
          return 'IU Product Catalog';
        case OFFER_ORIGIN_FILTER.CARE_SYNCHRONIZED:
          return 'Care (synchronisiert)';
        case OFFER_ORIGIN_FILTER.CARE_DESYNCHRONIZED:
          return 'Care (desynchronisiert)';
      }
    },
  },
};
</script>
