<template>
  <div class="flex flex-row">
    <div class="flex flex-col w-1/2">
      <h2 class="font-bold uppercase mb-9 mt-12 text-blue-600">
        Typ des Angebots
      </h2>
      <offer-type :text="offerTypeLabel" />

      <h2 class="font-bold uppercase mb-4 mt-20 text-blue-600">
        {{ assignmentSelectionHeadline }}
      </h2>
      <slot name="assignment-selection" />

      <h2 class="font-bold uppercase mb-9 mt-20 text-blue-600">
        Angebotsinformationen
      </h2>

      <div class="grid-flow-row grid grid-cols-12 gap-x-5 gap-y-7">
        <slot name="form" />
      </div>

      <div class="pt-12">
        <p class="text-xs text-gray-700">*Pflichtfelder</p>
      </div>
    </div>

    <div class="w-1/2 px-6 pt-24 flex items-start justify-center">
      <analyst-image class="w-64 h-auto" />
    </div>
  </div>
</template>

<script>
import AnalystImage from '@/components/AnalystImage';
import OfferType from '@/components/OfferType';

export default {
  name: 'OfferFormLayoutWrapper',

  components: {
    AnalystImage,
    OfferType,
  },

  props: {
    offerTypeLabel: {
      type: String,
      required: true,
    },
    assignmentSelectionHeadline: {
      type: String,
      required: true,
    },
  },
};
</script>
