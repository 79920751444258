import { ProductOfferApi } from '@/services/product-offer-api';
import { getDefaultSortOrder } from '@/constants/sortOrder';

export default {
  async search({ commit }, payload) {
    try {
      const { limit, offset, key, order, ...filter } = payload;

      commit('setIsLoading', true);
      commit('setFilter', filter);

      let defaultSort;
      if (!key || !order) {
        defaultSort = getDefaultSortOrder('courseOffer');
      } else {
        defaultSort = { key, order };
      }

      const { data, pagination } = await ProductOfferApi.searchCourseOffers(
        filter,
        defaultSort,
        limit,
        offset
      );

      commit('setSearchResult', data);
      commit('setPagination', pagination);
    } finally {
      commit('setIsLoading', false);
    }
  },

  async getAcademicStaffAssignments({ commit }, courseOfferId) {
    try {
      commit('setIsLoadingAcademicStaffAssignments', true);
      commit('resetAcademicStaffAssignments');

      const data = await ProductOfferApi.getAcademicStaffAssignments(
        courseOfferId
      );

      commit('setAcademicStaffAssignments', data);
    } finally {
      commit('setIsLoadingAcademicStaffAssignments', false);
    }
  },

  resetAcademicStaffAssignments({ commit }) {
    commit('resetAcademicStaffAssignments');
  },
};
