<template>
  <div class="flex flex-row items-center">
    <div>
      <progress-bar
        v-if="showProgressBar"
        :value="percentage"
        :force-overflow="forceAttendeesOverflow"
      />
      <div v-else class="text-blue-300 flex items-center">
        <n-icon icon="infinity" size="md" />
        <span class="ml-2 text-xs font-semibold">unbegrenzt</span>
      </div>
      <span class="text-xs text-gray-700">
        {{ label }}
      </span>
    </div>

    <div v-if="constraints" class="ml-2">
      <n-tooltip :content="constraints.hint">
        <n-icon :class="[constraints.color]" icon="alert" />
      </n-tooltip>
    </div>
  </div>
</template>

<script>
import ProgressBar from '@/components/ProgressBar';
import { NIcon, NTooltip } from '@careerpartner/nitro';

export default {
  name: 'AttendeeVisualization',

  components: { ProgressBar, NIcon, NTooltip },

  props: {
    currentValue: {
      type: Number,
      required: true,
    },
    maxValue: {
      type: Number,
      default: null,
    },
  },

  computed: {
    percentage() {
      return Number.isSafeInteger(this.maxValue) && this.maxValue > 0
        ? Math.floor((this.currentValue / this.maxValue) * 100)
        : 0;
    },

    forceAttendeesOverflow() {
      return this.maxValue === 0 && this.currentValue > 0;
    },

    showProgressBar() {
      return Number.isSafeInteger(this.maxValue);
    },

    label() {
      if (Number.isSafeInteger(this.maxValue)) {
        return `${this.currentValue}/${this.maxValue} Plätzen belegt`;
      }

      return `${this.currentValue} Plätze belegt`;
    },

    constraints() {
      if (this.maxValue === null || this.maxValue === 0) {
        return null;
      } else if (this.currentValue === this.maxValue) {
        return {
          color: 'text-orange-600',
          hint: 'Max. Teilnehmerzahl erreicht',
        };
      } else if (this.currentValue >= this.maxValue) {
        return {
          color: 'text-error',
          hint: 'Max. Teilnehmerzahl überschritten',
        };
      }

      return null;
    },
  },
};
</script>
