import { datadogLogs } from '@datadog/browser-logs';

export const useDatadog = (app) => {
  const DD_TOKEN = PARAMETER_STORE?.DD_TOKEN;

  if (!DD_TOKEN) {
    // eslint-disable-next-line no-console
    console.warn(
      'Could not initialize Datadog logging! \n Datadog Token is undefined'
    );

    return;
  }

  const initConfig = datadogLogs.getInitConfiguration();

  try {
    if (!initConfig) {
      datadogLogs.init({
        clientToken: DD_TOKEN,
        site: 'datadoghq.eu',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        env: process.env.DEPLOYMENT_ENV,
        service: 'fe.productoffer',
      });
    }

    app.config.errorHandler = (error, vm, info) => {
      const isAxiosError = error?.config?.url;
      const isNot500Error = error?.response?.status !== 500;

      if (info === undefined && (!isAxiosError || !isNot500Error)) {
        datadogLogs.logger.error(error?.message ?? 'Error', {}, error);
        throw error;
      }
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('Could not initialize Datadog browser log!');
  }
};
