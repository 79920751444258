import { ProductOfferApi } from '@/services/product-offer-api';

export default {
  async search({ commit }, payload) {
    try {
      const { limit, offset, ...filter } = payload;

      commit('setIsLoading', true);
      commit('setFilter', filter);
      const { data, pagination } = await ProductOfferApi.searchCourses(
        filter,
        limit,
        offset
      );

      commit('setSearchResult', data);
      commit('setPagination', pagination);
    } finally {
      commit('setIsLoading', false);
    }
  },
};
