export function didEnrollmentPeriodValuesChange(
  initialStartDate,
  initialEndDate,
  currentStartDate,
  currentEndDate
) {
  if (!currentStartDate && !currentEndDate) {
    return false;
  }

  const startDateChanged =
    (initialStartDate &&
      currentStartDate &&
      new Date(initialStartDate).setHours(0, 0, 0, 0) !==
        new Date(currentStartDate).setHours(0, 0, 0, 0)) ||
    (!initialStartDate && currentStartDate) ||
    (initialStartDate && !currentStartDate);

  const endDateChanged =
    (initialEndDate &&
      currentEndDate &&
      new Date(initialEndDate).setHours(0, 0, 0, 0) !==
        new Date(currentEndDate).setHours(0, 0, 0, 0)) ||
    (!initialEndDate && currentEndDate) ||
    (initialEndDate && !currentEndDate);

  return startDateChanged || endDateChanged;
}
