import {
  beforeEnterFeatureGuard,
  beforeEnterPermissionGuard,
} from '@/utils/router-guards';
import { FEATURE_FLAGS, SERVICE_ROUTE_PREFIX } from '@/constants';
import CourseOfferCreateView from '@/views/course-offer/CourseOfferCreateView.vue';
import CourseOfferUpdateView from '@/views/course-offer/CourseOfferUpdateView.vue';
import CourseOfferDetailView from '@/views/course-offer/CourseOfferDetailView.vue';
import CourseOfferListView from '@/views/course-offer/CourseOfferListView.vue';
import { BREADCRUMBS } from '@/routes/breadcrumbs';

const searchMeta = {
  title: 'Fachangebot',
  headline: 'Suche',
  breadcrumbLinks: [BREADCRUMBS.courseOfferList],
};

const offerBasicMeta = {
  title: 'Fachangebot',
  headline: 'Fachangebot',
};

const offerMeta = {
  ...offerBasicMeta,
  breadcrumbLinks: [BREADCRUMBS.courseOfferList],
};

const offerDetailsMeta = {
  ...offerBasicMeta,
  breadcrumbLinks: [
    BREADCRUMBS.courseOfferList,
    BREADCRUMBS.courseOfferDetails,
  ],
};
export const courseOfferRoutes = [
  {
    path: `${SERVICE_ROUTE_PREFIX}/course-offer`,
    name: 'course-offer-list',
    component: CourseOfferListView,
    meta: searchMeta,
  },
  {
    path: `${SERVICE_ROUTE_PREFIX}/course-offer/new`,
    name: 'course-offer-create',
    component: CourseOfferCreateView,
    meta: offerMeta,
    beforeEnter: (_to, _from, next) => {
      beforeEnterFeatureGuard(
        FEATURE_FLAGS.WRITE_UI_ENABLED,
        `${SERVICE_ROUTE_PREFIX}/course-offer`,
        next
      );
      beforeEnterPermissionGuard('course-offer', ['c'], next);
      next();
    },
  },
  {
    path: `${SERVICE_ROUTE_PREFIX}/course-offer/:courseOfferId/update`,
    name: 'course-offer-update',
    component: CourseOfferUpdateView,
    meta: offerMeta,
    beforeEnter: (_to, _from, next) => {
      beforeEnterFeatureGuard(
        FEATURE_FLAGS.WRITE_UI_ENABLED,
        `${SERVICE_ROUTE_PREFIX}/course-offer`,
        next
      );
      beforeEnterPermissionGuard('course-offer', ['u'], next);
      next();
    },
  },
  {
    path: `${SERVICE_ROUTE_PREFIX}/course-offer/:courseOfferId`,
    name: 'course-offer-detail',
    component: CourseOfferDetailView,
    meta: offerDetailsMeta,
    beforeEnter: (to, from, next) => {
      const courseOfferList = to.meta.breadcrumbLinks[0].to;
      courseOfferList.query = { ...courseOfferList.query, ...from.query };
      next();
    },
  },
];
