<template>
  <n-tooltip
    :content="`${disabled ? 'Du hast nicht die erforderlichen Rechte' : ''}`"
  >
    <n-button
      v-if="!!planningStatusActionLabel"
      variant="quaternary"
      :class="!disabled && buttonStyle.class"
      :disabled="disabled"
      @click="onClick"
    >
      <n-icon :icon="buttonStyle.icon" class="mr-2" />
      {{ planningStatusActionLabel }}
    </n-button>
  </n-tooltip>
</template>
<script>
import { NButton, NIcon, NTooltip } from '@careerpartner/nitro';
import { PLANNING_STATUS } from '@/constants/planning-status';
import { MIXINS } from '@/mixins';
export default {
  name: 'PlanningStatusButton',
  components: {
    NButton,
    NIcon,
    NTooltip,
  },
  mixins: [MIXINS],
  props: {
    currentPlanningStatus: {
      type: String,
      default: () => null,
      validator: (prop) =>
        Object.keys(PLANNING_STATUS).includes(prop) || prop === null,
    },
    possiblePlanningStatus: {
      type: String,
      default: () => null,
      validator: (prop) =>
        Object.keys(PLANNING_STATUS).includes(prop) || prop === null,
    },
    disabled: {
      type: Boolean,
      default: true,
      required: true,
    },
  },
  emits: ['onClick'],

  computed: {
    buttonStyle() {
      switch (this.possiblePlanningStatus) {
        case PLANNING_STATUS.PUBLISHED:
          return { icon: 'upload-outline', class: 'text-cta' };
        case PLANNING_STATUS.DRAFT:
          return { icon: 'mdi-arrow-u-right-bottom', class: 'text-status-red' };
        case PLANNING_STATUS.WITHDRAWN:
          return { icon: 'cancel', class: 'text-status-red' };
        default:
          return { icon: '', class: '' };
      }
    },
    planningStatusActionLabel() {
      return this.planningStatusActionTextFormatter(
        this.currentPlanningStatus,
        this.possiblePlanningStatus
      );
    },
  },

  methods: {
    onClick() {
      this.$emit('onClick', this.possiblePlanningStatus);
    },
  },
};
</script>
