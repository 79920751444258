export default {
  setLoading(state, payload) {
    state.loading = payload;
  },

  setCourseOffer(state, payload) {
    state.courseOffer = payload;
  },

  setAcademicStaffAssignments(state, payload) {
    state.academicStaffAssignments = payload;
  },

  resetAcademicStaffAssignments(state) {
    state.academicStaffAssignments = [];
  },

  setIsLoadingAcademicStaffAssignments(state, payload) {
    state.isLoadingAcademicStaffAssignments = payload;
  },

  setInvalidAcademicStaffAssignments(state, payload) {
    state.invalidAcademicStaffAssignments = payload;
  },

  resetInvalidAcademicStaffAssignments(state) {
    state.invalidAcademicStaffAssignments = [];
  },

  setIsLoadingInvalidAcademicStaffAssignments(state, payload) {
    state.isLoadingInvalidAcademicStaffAssignments = payload;
  },

  setAcademicStaffProfiles(state, payload) {
    state.academicStaffProfiles = payload;
  },

  setCourseOfferEditability(state, payload) {
    state.courseOfferEditable = payload;
  },
};
