import { getDefaultSortOrder } from '@/constants/sortOrder';

const BREADCRUMBS = {
  courseOfferList: {
    label: 'Fachangebote',
    to: {
      name: 'course-offer-list',
      query: {
        ...getDefaultSortOrder('courseOffer'),
        status: 'KNOWN',
      },
    },
  },
  courseOfferDetails: {
    label: 'Fachangebotsdetails',
    to: { name: 'course-offer-detail' },
  },
};

export { BREADCRUMBS };
