<template>
  <view-wrapper v-if="!loading && !!moduleOffer">
    <template #headline>
      <p class="flex align-center">
        {{ moduleOffer.name }}
        <planning-status-chip
          class="ml-1"
          :value="moduleOffer.planningStatus"
        />
      </p>
    </template>
    <n-toolbar>
      <n-toolbar-button
        v-if="isWriteUIEnabled"
        text="Modulangebot bearbeiten"
        icon="mdi-playlist-edit"
        :disabled-text="editableTooltipContent"
        :disabled="!userCanEditModuleOffer || !isModuleOfferEditable"
        @click="openModuleOfferEdit(moduleOffer.id)"
      />
      <n-tooltip
        v-if="isWriteUIEnabled && !isOnsiteOffer"
        :content="editableTooltipContent"
      >
        <planning-status-button
          v-if="isWriteUIEnabled"
          :current-planning-status="moduleOffer.planningStatus"
          :possible-planning-status="possiblePlanningStatus"
          :disabled="!userCanChangePlanningStatus || !isModuleOfferEditable"
          @on-click="openTransitionConfirmationDialog"
        />
      </n-tooltip>
      <offer-delete-button
        :enrollment-possible="isEnrollable"
        :enrollment-count="moduleOffer.enrollmentsCount"
        :origin="moduleOffer.origin"
        :planning-status="moduleOffer.planningStatus"
        @delete-offer="deleteModuleOffer(moduleOffer.id)"
      ></offer-delete-button>
    </n-toolbar>
    <care-warning-banner-and-modal
      v-if="legacyEnrollmentWarning || isOnsiteOffer"
      :is-onsite-offer="isOnsiteOffer"
    />
    <div class="flex flex-row w-full">
      <div class="flex-1">
        <p class="font-bold uppercase mb-5 mt-12 text-blue-600">
          Angebotsinformationen
        </p>
        <h1 class="font-bold uppercase mb-3 text-2xl text-black">
          {{ moduleOffer.name }}
        </h1>

        <div class="grid grid-cols-2 gap-8 mt-4">
          <div>
            <h2 class="font-bold">Angebotszeitraum</h2>
            <p class="text-gray-700">
              {{ yearSectionWithYearFormatter(moduleOffer) }}
            </p>
          </div>

          <div>
            <h2 class="font-bold">Herkunft</h2>
            <p class="text-gray-700">
              <a
                v-if="isCareOriginated"
                :href="careModuleOfferDeeplink"
                target="_blank"
                class="flex items-center"
              >
                Care
                {{
                  moduleOffer.isSyncedWithCare
                    ? '(synchronisiert)'
                    : '(desynchronisiert)'
                }}
                <n-icon class="ml-1 text-black" icon="open-in-new" />
              </a>
              <span v-else>{{ originFormatter(moduleOffer) }}</span>
            </p>
          </div>
        </div>

        <product-information-section :product="moduleOffer.moduleMainVersion">
          <template #productNameTitle>Name des Moduls</template>
          <template #productCodeTitle>Modulkürzel</template>
          <template #productVersionCodeTitle>Modulversion</template>
          <template #productECTSTitle>ECTS</template>
        </product-information-section>
      </div>
      <div class="w-96 ml-56">
        <div class="bg-bluegray p-5 mt-24 rounded">
          <div class="grid grid-cols-2 gap-4">
            <div class="flex justify-center">
              <img
                :src="crossLeggedImage"
                alt="Cross legged person"
                class="w-auto h-24"
              />
            </div>
            <div>
              <h2 class="font-bold mb-2">Teilnehmer</h2>
              <n-icon
                v-if="isOnsiteOffer"
                class="text-orange-600 icon-4xl"
                icon="alert"
              />
              <attendee-visualization
                v-else
                :current-value="moduleOffer.enrollmentsCount"
                :max-value="moduleOffer.maxParticipants"
              />
            </div>
          </div>
          <div
            class="border-t border-blue-300 pt-5 mt-4 grid grid-cols-2 gap-4"
          >
            <div class="text-center" :class="isOnsiteOffer && 'invisible'">
              <div class="flex items-center justify-center">
                <n-icon
                  :class="isEnrollable ? 'text-green-600' : 'text-gray-700'"
                  :icon="isEnrollable ? 'mdi-check' : 'mdi-close'"
                  class="icon-4xl"
                />
                <n-icon
                  v-if="legacyEnrollmentWarning"
                  class="text-orange-600 icon-4xl"
                  icon="alert"
                />
              </div>

              <p class="text-blue-300 font-bold text-xs">
                <template v-if="isEnrollable">
                  Einschreibung<br />möglich
                </template>
                <template v-else>
                  Einschreibung <br />
                  nicht möglich
                </template>
              </p>
            </div>
            <div>
              <h2 class="font-bold mb-2">Einschreibung</h2>
              <n-icon
                v-if="isOnsiteOffer"
                class="text-orange-600 icon-4xl"
                icon="alert"
              />
              <p v-else class="text-xs text-gray-700">
                <span class="font-bold text-blue-300 flex items-center">
                  <n-icon icon="infinity" />
                  <span class="ml-2">unbegrenzt</span>
                </span>
                Keine Einschreibefrist
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <planning-status-tranistion-confirmation-modal
      v-if="!isOnsiteOffer"
      :value="transitionConfirmationModal"
      :requested-planning-status="requestedPlanningStatus"
      :current-planning-status="moduleOffer.planningStatus"
      :course-offer-id="moduleOffer.id"
      product-name="Modulangebot"
      @close="closeTransitionConfirmationDialog"
      @publish="publish"
      @republish="republish"
      @unpublish="unpublish"
      @withdraw="withdraw"
    />
  </view-wrapper>
  <view-wrapper v-else>
    <div class="flex flex-col items-center mt-12">
      <n-simple-loader />
      <p class="text-cta">Modulangebot wird geladen...</p>
    </div>
  </view-wrapper>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import { FEATURE_FLAGS, IMAGE_URLS } from '@/constants';
import ViewWrapper from '@/components/ViewWrapper';
import PlanningStatusChip from '@/components/PlanningStatusChip.vue';
import CareWarningBannerAndModal from '@/components/CareWarningBannerAndModal';
import { hasConflictingBookingConstraints } from '@/utils/hasConflictingBookingConstraint';
import { hasPermissionFor } from '@/utils/permissionUtil';
import { isFeatureEnabled } from '@/utils/feature';
import PlanningStatusButton from '@/components/PlanningStatusButton.vue';
import { PLANNING_STATUS } from '@/constants/planning-status';
import AttendeeVisualization from '../../components/AttendeeVisualization.vue';
import OfferDeleteButton from '@/components/OfferDeleteButton.vue';
import ProductInformationSection from '@/components/ProductInformationSection.vue';
import { MIXINS } from '@/mixins';
import PlanningStatusTranistionConfirmationModal from '@/components/PlanningStatusTransitionConfirmationModal.vue';
import { OFFER_ORIGIN } from '@/constants/offerOrigin';
import { sanitizeUrl } from '@braintree/sanitize-url';
import urlJoin from 'url-join';

export default {
  name: 'ModuleOfferDetailView',
  components: {
    PlanningStatusTranistionConfirmationModal,
    ProductInformationSection,
    ViewWrapper,
    PlanningStatusChip,
    CareWarningBannerAndModal,
    PlanningStatusButton,
    AttendeeVisualization,
    OfferDeleteButton,
  },
  mixins: [MIXINS],

  beforeRouteEnter(to, _from, next) {
    if (!to.params.moduleOfferId) return;
    const { moduleOfferId } = to.params;
    next(async (vm) => {
      await Promise.all([
        vm.loadModuleOffer(moduleOfferId),
        vm.loadModuleOfferEditability(moduleOfferId),
      ]);
    });
  },
  data() {
    return {
      possiblePlanningStatus: null,
      transitionConfirmationModal: false,
      requestedPlanningStatus: null,
    };
  },

  computed: {
    ...mapFields('moduleOffer', [
      'loading',
      'moduleOffer',
      'moduleOfferEditable',
    ]),

    crossLeggedImage() {
      return IMAGE_URLS.CROSS_LEGGED;
    },

    isEnrollable() {
      return this.enrollmentStateFormatter(this.moduleOffer.enrollmentState);
    },

    legacyEnrollmentWarning() {
      return hasConflictingBookingConstraints(this.moduleOffer);
    },

    isModuleOfferEditable() {
      return !!this.moduleOfferEditable;
    },

    userCanEditModuleOffer() {
      return hasPermissionFor('module-offer', ['u']);
    },

    isWriteUIEnabled() {
      return isFeatureEnabled(FEATURE_FLAGS.WRITE_UI_ENABLED);
    },
    isOnsiteOffer() {
      return !this.moduleOffer.isDistanceLearningOffer;
    },

    userCanChangePlanningStatus() {
      if (!this.possiblePlanningStatus) {
        return false;
      }

      switch (this.possiblePlanningStatus) {
        case PLANNING_STATUS.PUBLISHED:
          // if the current planning status is withdrawn this need republish permission
          if (this.moduleOffer.planningStatus === PLANNING_STATUS.WITHDRAWN) {
            return hasPermissionFor('republish-offer', ['x']);
          }
          return hasPermissionFor('publish-offer', ['x']);
        case PLANNING_STATUS.DRAFT:
          return hasPermissionFor('unpublish-offer', ['x']);
        case PLANNING_STATUS.WITHDRAWN:
          return hasPermissionFor('withdraw-offer', ['x']);
      }

      return false;
    },
    editableTooltipContent() {
      if (!this.userCanEditModuleOffer) {
        return 'Du verfügst nicht über die erforderlichen Rechte, um Modulangebote zu bearbeiten';
      } else if (!this.moduleOfferEditable) {
        return 'Dieses Modulangebot muss weiterhin in Care verwaltet werden';
      }

      return '';
    },
    isCareOriginated() {
      return this.moduleOffer.origin === OFFER_ORIGIN.CARE;
    },
    careBaseUrl() {
      return PARAMETER_STORE.CARE_BASE_URL;
    },
    careModuleOfferDeeplink() {
      return sanitizeUrl(
        urlJoin(
          this.careBaseUrl,
          `/home#/course/${this.moduleOffer.careOfferId}/edit`
        )
      );
    },
  },

  mounted() {
    const { moduleOfferId } = this.$route.params;
    if (this.isWriteUIEnabled) {
      this.determineNextPossibleTransition(moduleOfferId);
    }
  },

  methods: {
    ...mapActions('moduleOffer', [
      'loadModuleOffer',
      'loadModuleOfferEditability',
      'getPossibleTransitions',
      'publishModuleOffer',
      'republishModuleOffer',
      'unpublishModuleOffer',
      'withdrawModuleOffer',
      'deleteModuleOffer',
    ]),

    async openModuleOfferEdit(moduleOfferId) {
      await this.$router.push({
        name: 'module-offer-update',
        params: { moduleOfferId: moduleOfferId },
      });
    },

    openTransitionConfirmationDialog(requestedPlanningStatus) {
      this.requestedPlanningStatus = requestedPlanningStatus;
      this.transitionConfirmationModal = true;
    },

    closeTransitionConfirmationDialog() {
      this.requestedPlanningStatus = null;
      this.transitionConfirmationModal = false;
    },

    async determineNextPossibleTransition(moduleOfferId) {
      if (!moduleOfferId) {
        return;
      }
      this.possiblePlanningStatus = (
        await this.getPossibleTransitions(moduleOfferId)
      ).data.nextTransition;
    },

    async publish() {
      await this.publishModuleOffer(this.moduleOffer.id);
      await this.refreshDetailsPage(this.moduleOffer.id);
    },

    async republish() {
      await this.republishModuleOffer(this.moduleOffer.id);
      await this.refreshDetailsPage(this.moduleOffer.id);
    },

    async unpublish() {
      await this.unpublishModuleOffer(this.moduleOffer.id);
      await this.refreshDetailsPage(this.moduleOffer.id);
    },

    async withdraw() {
      await this.withdrawModuleOffer(this.moduleOffer.id);
      await this.refreshDetailsPage(this.moduleOffer.id);
    },

    async refreshDetailsPage(moduleOfferId) {
      await this.loadModuleOffer(moduleOfferId);

      if (!this.isWriteUIEnabled) {
        return;
      }

      await this.determineNextPossibleTransition(moduleOfferId);
    },
  },
};
</script>
