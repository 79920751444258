export const SERVICE_ROUTE_PREFIX = `/services/product-offer`;

export const IMAGE_URLS = {
  CROSS_LEGGED:
    'https://res.cloudinary.com/iubh/image/upload/v1658914922/IT%20-%20Epos%20/illustrations/epos-teilnehmer.svg',
  LOCKED_WARNING:
    'https://res.cloudinary.com/iubh/image/upload/v1658914924/IT%20-%20Epos%20/illustrations/epos-CARE-configuration.svg',
  TRASH:
    'https://res.cloudinary.com/iubh/image/upload/v1633071257/IT%20-%20Epos%20/illustrations/epos-delete.svg',
  BLOCKED_TRASH:
    'https://res.cloudinary.com/iubh/image/upload/v1633071744/IT%20-%20Epos%20/illustrations/epos-delete-not-possible-.svg',
  ANALYST:
    'https://res.cloudinary.com/iubh/image/upload/v1663667524/IT%20-%20Epos%20/illustrations/epos-new-product-offer.svg',
  OPEN_BOOK:
    'https://res.cloudinary.com/iubh/image/upload/v1663667524/IT%20-%20Epos%20/illustrations/epos-fachangebot.svg',
  STACK_OF_BOOKS:
    'https://res.cloudinary.com/iubh/image/upload/v1663667524/IT%20-%20Epos%20/illustrations/epos-modulangebot.svg',
  BACKPACK:
    'https://res.cloudinary.com/iubh/image/upload/v1663667524/IT%20-%20Epos%20/illustrations/epos-publish-offer.svg',
  BACKPACK_DELETE:
    'https://res.cloudinary.com/iubh/image/upload/v1663667524/IT%20-%20Epos%20/illustrations/epos-withdraw-offer.svg',
};

export const FEATURE_FLAGS = {
  WRITE_UI_ENABLED: 'WRITE_UI_ENABLED',
};
