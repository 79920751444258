<template>
  <div>
    <h2 class="font-bold">
      <slot name="title"></slot>
    </h2>
    <p v-if="!hasProduct" class="text-gray-700">
      Unbekannt <n-icon class="text-orange-600" icon="alert-outline" />
    </p>
    <p v-else class="text-gray-700">
      {{ value }}
    </p>
  </div>
</template>
<script>
import { NIcon } from '@careerpartner/nitro';
export default {
  name: 'ProductInfo',
  components: { NIcon },
  props: {
    hasProduct: {
      type: Boolean,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: 'Unbekannt',
    },
  },
};
</script>
