<template>
  <view-wrapper>
    <div class="flex items-center justify-center h-screen">
      <n-error-page variant="notFound">
        <template #actions>
          <n-button variant="tertiary" @click="goBack"> Zurück </n-button>

          <n-button variant="tertiary" @click="goHome"> Home </n-button>
        </template>
      </n-error-page>
    </div>
  </view-wrapper>
</template>
<script>
import { NErrorPage } from '@careerpartner/nitro';
import ViewWrapper from '@/components/ViewWrapper.vue';

export default {
  name: 'NotFound404',
  components: {
    ViewWrapper,
    NErrorPage,
  },
  methods: {
    goBack() {
      window.history.back();
    },
    goHome() {
      this.$router.replace({ name: 'course-offer-list' });
    },
  },
};
</script>
