<template>
  <div>
    <n-tooltip :content="toolTipText">
      <n-button variant="quaternary" :disabled="disabled" @click="openModal">
        <n-icon icon="trash-can-outline" class="mr-2" />
        Löschen
      </n-button>
      <delete-warning-modal
        :is-modal-open="isModalOpen"
        @close="isModalOpen = false"
        @delete-confirmed="closeModalAndEmitDelete()"
      ></delete-warning-modal>
    </n-tooltip>
  </div>
</template>
<script>
import { NButton, NIcon, NTooltip } from '@careerpartner/nitro';
import { PLANNING_STATUS } from '@/constants/planning-status';
import { OFFER_ORIGIN } from '@/constants/offerOrigin';
import { hasPermissionFor } from '@/utils/permissionUtil';
import DeleteWarningModal from '@/components/DeleteWarningModal.vue';

export default {
  name: 'OfferDeleteButton',

  components: {
    NButton,
    NTooltip,
    NIcon,
    DeleteWarningModal,
  },

  props: {
    origin: {
      type: String,
      required: true,
    },
    planningStatus: {
      type: String,
      required: true,
    },
    enrollmentCount: {
      type: Number,
      required: true,
    },
    enrollmentPossible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['deleteOffer'],
  data: () => ({
    isModalOpen: false,
  }),
  computed: {
    disabled() {
      return (
        this.enrollmentCount > 0 ||
        this.planningStatus !== PLANNING_STATUS.DRAFT ||
        this.origin === OFFER_ORIGIN.CARE ||
        this.enrollmentPossible ||
        !this.userCanDeleteCourseOffer()
      );
    },
    toolTipText() {
      if (this.origin === OFFER_ORIGIN.CARE) {
        return 'Dieses Angebot muss weiterhin in Care verwaltet werden.';
      }

      if (this.planningStatus !== PLANNING_STATUS.DRAFT) {
        return 'Angebote können nur im Status “Entwurf” gelöscht werden.';
      }

      if (!this.userCanDeleteCourseOffer()) {
        return 'Du hast nicht die nötige Berechtigung, um Angebote zu löschen.';
      }

      if (this.enrollmentCount > 0) {
        return 'Angebote, für die Einschreibungen von Studierenden vorliegen, können nicht gelöscht werden.';
      }

      if (this.enrollmentPossible) {
        return 'Angebote, die aktuell zur Einschreibung geöffnet sind, können nicht gelöscht werden.';
      }

      return '';
    },
  },
  methods: {
    deleteOffer() {
      this.$emit('deleteOffer', this.offerId);
    },
    closeModalAndEmitDelete() {
      this.isModalOpen = false;

      this.$emit('deleteOffer', this.offerId);
    },
    userCanDeleteCourseOffer() {
      return hasPermissionFor('course-offer', ['d']);
    },
    openModal() {
      this.isModalOpen = true;
    },
  },
};
</script>
