import { createApiClient } from '@epos/core-applets';
import { successHandler, errorHandler } from '@/utils/responseHandlers';
import { transformSearchParams } from '@/utils/transform-search-params';

export const ApiClient = createApiClient({ basePath: '/product-offer' });
ApiClient.instance.interceptors.response.use(successHandler, errorHandler);

const pluckData = (resp) => resp.data;

const searchCourseOffers = (filter, sort, limit = 20, offset = 0) =>
  ApiClient.instance
    .get('v1/course-offers/list', {
      ...transformSearchParams(filter, limit, offset, sort),
    })
    .then(pluckData);

const searchModuleOffers = (filter, sort, limit = 20, offset = 0) =>
  ApiClient.instance
    .get('v1/module-offers/list', {
      ...transformSearchParams(filter, limit, offset, sort),
    })
    .then(pluckData);

const getCourseOffer = (courseOfferId) =>
  ApiClient.instance.get(`v1/course-offers/${courseOfferId}`).then(pluckData);

const getModuleOffer = (moduleOfferId) =>
  ApiClient.instance.get(`v1/module-offers/${moduleOfferId}`).then(pluckData);

const getAcademicStaffAssignments = (courseOfferId) =>
  ApiClient.instance
    .get(`v1/course-offers/${courseOfferId}/academic-staff-assignments/list`)
    .then(pluckData);

const getInvalidAcademicStaffAssignments = (courseOfferId) =>
  ApiClient.instance
    .get(
      `v1/course-offers/${courseOfferId}/academic-staff-assignments/listInvalidAssignments`
    )
    .then(pluckData);

const createAcademicStaffAssignment = (academicStaffAssignment) =>
  ApiClient.instance
    .post(
      `v1/course-offers/${academicStaffAssignment.courseOfferId}/academic-staff-assignments`,
      academicStaffAssignment
    )
    .then(pluckData);

const updateAcademicStaffAssignmentPeriod = (
  courseOfferId,
  academicStaffAssignment
) =>
  ApiClient.instance
    .patch(
      `v1/course-offers/${courseOfferId}/academic-staff-assignments/${academicStaffAssignment.id}`,
      academicStaffAssignment
    )
    .then(pluckData);

const deleteAcademicStaffAssignment = (
  courseOfferId,
  academicStaffAssignmentId
) =>
  ApiClient.instance
    .delete(
      `v1/course-offers/${courseOfferId}/academic-staff-assignments/${academicStaffAssignmentId}`
    )
    .then(pluckData);

const getAcademicStaffProfiles = (name) =>
  ApiClient.instance
    .get('v1/academic-staff/profiles', { params: { name } })
    .then(pluckData);

const searchCourses = (filter, limit = 5, offset = 0) =>
  ApiClient.instance
    .get('v2/courses/list', {
      ...transformSearchParams(filter, limit, offset),
    })
    .then(pluckData);

const createCourseOffer = (courseOffer) =>
  ApiClient.instance.post('v1/course-offers', courseOffer).then(pluckData);

const updateCourseOffer = (courseOffer) => {
  const { id, ...rest } = courseOffer;

  return ApiClient.instance.put(`v1/course-offers/${id}`, rest).then(pluckData);
};

const getPossibleTransitions = (courseOfferId) => {
  return ApiClient.instance
    .get(`v1/course-offers/${courseOfferId}/next-transition`)
    .then(pluckData);
};

const publishCourseOfferPlanningStatus = (courseOfferId) =>
  ApiClient.instance
    .post(`v1/course-offers/${courseOfferId}/planning-status/publish`)
    .then(pluckData);

const republishCourseOfferPlanningStatus = (courseOfferId) =>
  ApiClient.instance
    .post(`v1/course-offers/${courseOfferId}/planning-status/republish`)
    .then(pluckData);

const unpublishCourseOffer = (courseOfferId) => {
  return ApiClient.instance
    .post(`v1/course-offers/${courseOfferId}/planning-status/unpublish`)
    .then(pluckData);
};

const withdrawCourseOffer = (courseOfferId) =>
  ApiClient.instance
    .post(`v1/course-offers/${courseOfferId}/planning-status/withdraw`)
    .then(pluckData);

const createModuleOffer = (moduleOffer) =>
  ApiClient.instance.post('v1/module-offers', moduleOffer).then(pluckData);

const searchModules = (filter, limit = 5, offset = 0) =>
  ApiClient.instance
    .get('v2/modules/list', {
      ...transformSearchParams(filter, limit, offset),
    })
    .then(pluckData);

const updateModuleOffer = (moduleOffer) => {
  const { id, ...rest } = moduleOffer;

  return ApiClient.instance.put(`v1/module-offers/${id}`, rest).then(pluckData);
};

const getModuleOfferPossibleTransitions = (moduleOfferId) =>
  ApiClient.instance
    .get(`v1/module-offers/${moduleOfferId}/next-transition`)
    .then(pluckData);

const publishModuleOffer = (moduleOfferId) =>
  ApiClient.instance
    .post(`v1/module-offers/${moduleOfferId}/planning-status/publish`)
    .then(pluckData);

const republishModuleOffer = (moduleOfferId) =>
  ApiClient.instance
    .post(`v1/module-offers/${moduleOfferId}/planning-status/republish`)
    .then(pluckData);

const unpublishModuleOffer = (moduleOfferId) =>
  ApiClient.instance
    .post(`v1/module-offers/${moduleOfferId}/planning-status/unpublish`)
    .then(pluckData);

const withdrawModuleOffer = (moduleOfferId) =>
  ApiClient.instance
    .post(`v1/module-offers/${moduleOfferId}/planning-status/withdraw`)
    .then(pluckData);

const getCourseOfferEditability = (courseOfferId) => {
  return ApiClient.instance
    .get(`v1/course-offers/${courseOfferId}/is-editable`)
    .then(pluckData);
};

const getModuleOfferEditability = (courseOfferId) => {
  return ApiClient.instance
    .get(`v1/module-offers/${courseOfferId}/is-editable`)
    .then(pluckData);
};

const deleteCourseOffer = (courseOfferId) =>
  ApiClient.instance
    .delete(`v1/course-offers/${courseOfferId}`)
    .then(pluckData);

const deleteModuleOffer = (moduleOfferId) =>
  ApiClient.instance
    .delete(`v1/module-offers/${moduleOfferId}`)
    .then(pluckData);

export const ProductOfferApi = {
  searchCourseOffers,
  searchModuleOffers,
  searchCourses,
  getCourseOffer,
  getModuleOffer,
  getAcademicStaffAssignments,
  getInvalidAcademicStaffAssignments,
  createAcademicStaffAssignment,
  updateAcademicStaffAssignmentPeriod,
  deleteAcademicStaffAssignment,
  getAcademicStaffProfiles,
  createCourseOffer,
  updateCourseOffer,
  getPossibleTransitions,
  publishCourseOfferPlanningStatus,
  republishCourseOfferPlanningStatus,
  unpublishCourseOffer,
  withdrawCourseOffer,
  createModuleOffer,
  searchModules,
  updateModuleOffer,
  getModuleOfferPossibleTransitions,
  publishModuleOffer,
  republishModuleOffer,
  unpublishModuleOffer,
  withdrawModuleOffer,
  getCourseOfferEditability,
  getModuleOfferEditability,
  deleteCourseOffer,
  deleteModuleOffer,
};
