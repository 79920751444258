import { isAfter, isEqual } from 'date-fns';
import { parseDate as parse } from '@/utils/date';

const validate = (
  value,
  [target],
  { inclusion = true, format = 'yyyy-MM-dd', allowEmptyTarget = true } = {}
) => {
  value = parse(value, format);
  target = parse(target, format);

  if (!target) {
    return allowEmptyTarget || 'Das Referenzdatum muss ausgefüllt sein.';
  }

  if (!value) {
    return false;
  }

  const valid = isAfter(value, target) || (inclusion && isEqual(value, target));

  return (
    valid ||
    `Das Datum muss größer ${
      inclusion ? 'oder gleich ' : ''
    } ${target.toLocaleDateString()} sein.`
  );
};

export { validate };
