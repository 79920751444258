export default () => ({
  isLoading: false,
  searchResult: [],
  academicStaffAssignments: [],
  isLoadingAcademicStaffAssignments: false,
  pagination: {
    limit: 20,
    offset: 0,
    totalCount: 0,
  },
  filter: {
    search: '',
  },
});
