import { ProductOfferApi } from '@/services/product-offer-api';
import { getDefaultSortOrder } from '@/constants/sortOrder';

export default {
  async search({ commit }, payload) {
    try {
      const { limit, offset, key, order, ...filter } = payload;

      commit('setIsLoading', true);
      commit('setFilter', filter);

      let defaultSort;
      if (!key || !order) {
        defaultSort = getDefaultSortOrder('moduleOffer');
      } else {
        defaultSort = { key, order };
      }

      const { data, pagination } = await ProductOfferApi.searchModuleOffers(
        filter,
        defaultSort,
        limit,
        offset
      );

      commit('setSearchResult', data);
      commit('setPagination', pagination);
    } finally {
      commit('setIsLoading', false);
    }
  },
};
