import { mainRoutes } from '@/routes/main';
import { errorRoutes } from '@/routes/errors';
import { courseOfferRoutes } from '@/routes/course-offer';
import { moduleOfferRoutes } from '@/routes/module-offer';

export const routes = [
  ...mainRoutes,
  ...errorRoutes,
  ...courseOfferRoutes,
  ...moduleOfferRoutes,
];
