<template>
  <table
    aria-label="Table for academic staff assignments of course offer"
    class="w-1/2 mt-3 mr-3 mb-3"
  >
    <thead class="text-left">
      <tr>
        <th class="py-2 bg-blue-200">Lehrende</th>
        <th class="py-2 bg-blue-200">Rolle</th>
      </tr>
    </thead>

    <tbody>
      <tr v-if="isLoading">
        <td class="text-center" colspan="2">
          <div class="pt-2">
            <n-simple-loader class="inline-flex" />
          </div>
        </td>
      </tr>
      <tr v-else-if="!data.length">
        <td class="py-2" colspan="2">
          <p>Keine Lehrenden zugeordnet</p>
        </td>
      </tr>
      <template v-else>
        <tr
          v-for="item in data"
          :key="item.id"
          class="even:bg-blue-50 odd:bg-blue-100"
        >
          <td class="py-2">
            <academic-staff-assignment-profile-full-name
              :assignment="item"
            ></academic-staff-assignment-profile-full-name>
          </td>
          <td class="py-2">
            {{ academicStaffAssignmentRoleFormatter(item.role) }}
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import { NSimpleLoader } from '@careerpartner/nitro';
import AcademicStaffAssignmentProfileFullName from '@/components/AcademicStaffAssignmentProfileFullName.vue';
import { MIXINS } from '@/mixins';
import { ProductOfferApi } from '@/services/product-offer-api';

export default {
  name: 'AcademicStaffAssignmentsTable',

  components: {
    NSimpleLoader,
    AcademicStaffAssignmentProfileFullName,
  },

  mixins: [MIXINS],

  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
  emits: ['select'],

  data: () => ({
    headerItems: [
      {
        class: 'w-6/12 px-3',
        label: 'Lehrende',
        key: 'staff',
      },
      {
        class: 'w-6/12 px-3',
        label: 'Rolle',
        key: 'role',
      },
    ],
    isLoading: false,
    data: [],
  }),

  async mounted() {
    await this.getAcademicStaffAssignments();
  },

  methods: {
    async getAcademicStaffAssignments() {
      this.isLoading = true;
      try {
        this.data = await ProductOfferApi.getAcademicStaffAssignments(
          this.offer.id
        );
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
