import { createStore } from 'vuex';
import app from '@/store/modules/app';
import courseOffer from '@/store/modules/course-offer';
import moduleOffer from '@/store/modules/module-offer';
import errors from '@/store/modules/errors';
import courseOfferSearch from '@/store/modules/course-offer-search';
import moduleOfferSearch from '@/store/modules/module-offer-search';
import courseSearch from '@/store/modules/course-search';
import moduleSearch from '@/store/modules/module-search';
import snackbar from '@/store/modules/snackbar';

export const store = createStore({
  modules: {
    app,
    courseOffer,
    moduleOffer,
    errors,
    courseOfferSearch,
    moduleOfferSearch,
    courseSearch,
    moduleSearch,
    snackbar,
  },
});
