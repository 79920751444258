<template>
  <p class="text-right">
    Anzeige aller
    <span class="font-semibold text-primary">{{ totalCount }}</span>
    Modulangebote.
  </p>
  <n-table :header-items="headerItems" :data="data" :is-loading="isLoading">
    <template #th-name="{ item }">
      <span class="mr-2">{{ item.label }}</span>
      <column-sort-button
        column-name="moduleOffer.name"
        @on-new-sort="onNewSort"
      />
    </template>
    <template #th-module="{ item }">
      <span class="mr-2">{{ item.label }}</span>
      <column-sort-button
        column-name="module.moduleCode"
        @on-new-sort="onNewSort"
      />
    </template>
    <template #filter-time="{ close }">
      <n-table-filter-radio
        id="module-offer-table__filter-time"
        :options="filtersByTimeOptions"
        data-test="table-filter-by-time"
        :model-value="queryTime"
        @update:model-value="$emit('filter', { time: $event })"
        @close="close"
      />
    </template>
    <template #filter-status="{ close }">
      <n-table-filter-radio
        id="module-offer-table__filter-status"
        :options="filtersByStatusOptions"
        data-test="table-filter-by-status"
        :model-value="queryStatus"
        @update:model-value="$emit('filter', { status: $event })"
        @close="close"
      />
    </template>
    <template #filter-bookable="{ close }">
      <n-table-filter-radio
        id="module-offer-table__filter-bookable"
        :options="filtersByBookableOptions"
        data-test="table-filter-by-bookable"
        :model-value="queryBookable"
        @update:model-value="$emit('filter', { bookable: $event })"
        @close="close"
      />
    </template>
    <template #filter-enrollments="{ close }">
      <n-table-filter-radio
        id="module-offer-table__filter-enrollments"
        :options="filtersByEnrollmentsCount"
        data-test="table-filter-by-enrollments"
        :model-value="queryEnrollment"
        @update:model-value="$emit('filter', { enrollment: $event })"
        @close="close"
      />
    </template>
    <template #filter-origin="{ close }">
      <n-table-filter-radio
        id="course-offer-table__filter-origin"
        :options="filtersByOrigin"
        data-test="table-filter-by-origin"
        :model-value="queryOrigin"
        @update:model-value="$emit('filter', { origin: $event })"
        @close="close"
      />
    </template>
    <template #row="{ item }">
      <n-table-row
        :item="item"
        :class="
          hasAnyConflict(item)
            ? 'bg-gradient-to-r from-orange-600 to-orange-600/0 from-[8px] to-[8px]'
            : ''
        "
        class="odd:bg-blue-100 relative"
      >
        <module-offer-table-row :item="item" />
      </n-table-row>
    </template>

    <template #no-results>
      <n-empty-results
        v-if="isSearch"
        text="Keine Suchergebnisse gefunden"
        reset-text="Suche & Filter zurücksetzen"
        @reset="resetFilter"
      />
      <n-empty-data v-else text="Keine Modulangebote vorhanden" />
    </template>
  </n-table>
</template>

<script>
import { ENROLLMENT_STATUS } from '@/constants/enrollmentStatus';
import ModuleOfferTableRow from '@/components/ModuleOfferTableRow';
import ColumnSortButton from '@/components/ColumnSortButton.vue';
import { getDefaultSortOrder } from '@/constants/sortOrder';

import { determineCurrentSortOrder } from '@/utils/determineCurrentSortOrder';
import { OFFER_ORIGIN_FILTER } from '@/constants/offerOriginFilter';
import { hasConflictingBookingConstraints } from '@/utils/hasConflictingBookingConstraint';

export default {
  name: 'ModuleOfferTable',

  components: {
    ModuleOfferTableRow,
    ColumnSortButton,
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
  },
  emits: { filter: null, reset: null, sort: null },

  data: () => ({
    headerItems: [
      {
        class: 'w-4/12 px-3',
        label: 'Modulangebot',
        key: 'name',
      },
      {
        class: 'w-2/12 px-3',
        label: 'Modul',
        key: 'module',
      },
      {
        class: 'w-2/12 px-3',
        label: 'Zeitraum',
        key: 'time',
      },
      {
        class: 'w-2/12 px-3',
        label: 'Status',
        key: 'status',
      },
      {
        class: 'w-2/12 px-3',
        label: 'Einschreibung',
        key: 'bookable',
      },
      {
        class: 'w-2/12 px-3',
        label: 'Herkunft',
        key: 'origin',
      },
      {
        class: 'w-2/12 px-3',
        label: 'Teilnehmer',
        key: 'enrollments',
      },
    ],
    filtersByStatusOptions: [
      { value: 'DRAFT', label: 'Entwurf' },
      { value: 'WITHDRAWN', label: 'Zurückgenommen' },
      { value: 'PUBLISHED', label: 'Veröffentlicht' },
      { value: 'UNKNOWN', label: 'Unbekannt' },
      { value: 'KNOWN', label: 'Nicht Unbekannt' },
    ],
    filtersByBookableOptions: [
      { value: 'true', label: 'Buchbar' },
      { value: 'false', label: 'Nicht buchbar' },
    ],
    filtersByTimeOptions: [
      { value: 'false', label: 'Permanent' },
      { value: 'true', label: 'Nicht permanent' },
    ],
    filtersByEnrollmentsCount: [
      { value: ENROLLMENT_STATUS.NONE, label: 'Keine Teilnehmer' },
      {
        value: ENROLLMENT_STATUS.HAS_ENROLLMENTS,
        label: 'Teilnehmer eingebucht',
      },
    ],
    filtersByOrigin: [
      { value: OFFER_ORIGIN_FILTER.EPOS, label: 'EPOS' },
      {
        value: OFFER_ORIGIN_FILTER.PRODUCT,
        label: 'IU Product Catalog',
      },
      {
        value: OFFER_ORIGIN_FILTER.CARE_SYNCHRONIZED,
        label: 'Care (synchronisiert)',
      },
      {
        value: OFFER_ORIGIN_FILTER.CARE_DESYNCHRONIZED,
        label: 'Care (desynchronisiert)',
      },
    ],
    currentSort: getDefaultSortOrder('courseOffer'),
  }),

  computed: {
    queryStatus() {
      const { status } = this.$route.query;

      if (status) return status;

      return 'KNOWN';
    },

    queryTime() {
      const { time } = this.$route.query;

      return time ?? '';
    },

    queryBookable() {
      const { bookable } = this.$route.query;

      return bookable ?? '';
    },

    queryEnrollment() {
      const { enrollment } = this.$route.query;

      return enrollment ?? '';
    },

    queryOrigin() {
      const { origin } = this.$route.query;

      return origin ?? '';
    },
  },

  mounted() {
    if (this.$route.query.key) {
      this.currentSort.key = this.$route.query.key;
      this.currentSort.order = this.$route.query.order;
    }
  },

  methods: {
    resetFilter() {
      this.$emit('reset');
    },

    onNewSort(sort) {
      const currentSortOrder = determineCurrentSortOrder(
        sort,
        this.currentSort
      );

      this.currentSort = currentSortOrder;

      this.$emit('sort', currentSortOrder);
    },

    constraints(offer) {
      return {
        hasConflictingBooking: hasConflictingBookingConstraints(offer),
      };
    },

    hasAnyConflict(offer) {
      return Object.values(this.constraints(offer)).some(Boolean);
    },
  },
};
</script>
