<template>
  <p v-if="!assignmentHasDeletedAcademicStaffProfile(assignment)">
    <a
      v-if="!!assignment.profileId && userCanReadAcademicStaffProfiles"
      :href="`services/staff/profiles/${assignment.profileId}`"
      class="font-bold"
    >
      {{ assignment.profile.givenName }} {{ assignment.profile.familyName }}
    </a>
    <span v-else>
      {{ assignment.profile.givenName }} {{ assignment.profile.familyName }}
    </span>
  </p>
  <span v-else>
    <n-tooltip content="Lehrende in Lehrerndenverwaltung entfernt">
      <n-icon class="text-error" icon="alert" />
    </n-tooltip>
    <span class="ml-1">
      {{ assignment.profile.givenName }} {{ assignment.profile.familyName }}
      <span class="font-bold">(entfernt)</span>
    </span>
  </span>
</template>

<script>
import { NIcon, NTooltip } from '@careerpartner/nitro';
import { hasPermissionFor } from '@/utils/permissionUtil';

export default {
  name: 'AcademicStaffAssignmentProfileFullName',

  components: {
    NIcon,
    NTooltip,
  },

  props: {
    assignment: {
      type: Object,
      required: true,
    },
  },

  computed: {
    userCanReadAcademicStaffProfiles() {
      return hasPermissionFor('academic-staff', ['r']);
    },
  },

  methods: {
    assignmentHasDeletedAcademicStaffProfile(academicStaffAssignment) {
      return !!academicStaffAssignment.profile?.deletedAt;
    },
  },
};
</script>
