import Not_found_404 from '@/views/error_pages/not_found_404.vue';
import Forbidden_403 from '@/views/error_pages/forbidden_403.vue';
import GeneralErrorPage from '@/views/error_pages/general_error_page.vue';
import { SERVICE_ROUTE_PREFIX } from '@/constants';
import { BREADCRUMBS } from '@/routes/breadcrumbs';

const errorMeta = {
  title: 'Fehler - Dokumentenverwaltung',
  headline: 'Fehler',
  breadcrumbLinks: [BREADCRUMBS.courseOfferList],
};

export const errorRoutes = [
  {
    path: `${SERVICE_ROUTE_PREFIX}/404`,
    name: 'NotFound404',
    component: Not_found_404,
    meta: errorMeta,
  },
  {
    path: `${SERVICE_ROUTE_PREFIX}/403`,
    name: 'Forbidden403',
    component: Forbidden_403,
    meta: errorMeta,
  },
  {
    path: `${SERVICE_ROUTE_PREFIX}/error`,
    name: 'GeneralErrorPage',
    component: GeneralErrorPage,
    meta: errorMeta,
  },
];
