<template>
  <view-wrapper v-if="!loading && !!courseOffer">
    <template #headline>
      <p class="flex align-center">
        {{ courseOffer.name }}
        <planning-status-chip
          class="ml-1"
          :value="courseOffer.planningStatus"
        />
      </p>
    </template>
    <n-toolbar>
      <n-toolbar-button
        v-if="isWriteUIEnabled"
        text="Fachangebot bearbeiten"
        icon="mdi-playlist-edit"
        :disabled-text="editableTooltipContent"
        :disabled="!userCanEditCourseOffer || !isCourseOfferEditable"
        @click="openCourseOfferEdit(courseOffer.id)"
      />
      <n-tooltip
        v-if="isWriteUIEnabled && !isOnsiteOffer"
        :content="editableTooltipContent"
      >
        <planning-status-button
          v-if="isWriteUIEnabled"
          :current-planning-status="courseOffer.planningStatus"
          :possible-planning-status="possiblePlanningStatus"
          :disabled="!userCanChangePlanningStatus || !isCourseOfferEditable"
          @on-click="openTransitionConfirmationDialog"
        />
      </n-tooltip>

      <n-toolbar-button
        text="Lehrende hinzufügen"
        icon="mdi-human-male-board"
        :disabled-text="
          !userCanCreateAcademicStaffAssignment
            ? 'Du verfügst nicht über die erforderlichen Rechte, um Lehrende hinzuzufügen'
            : ''
        "
        :disabled="!userCanCreateAcademicStaffAssignment"
        @click="openAcademicStaffAssignmentModal(null)"
      />
      <offer-delete-button
        :enrollment-possible="isEnrollable"
        :enrollment-count="courseOffer.enrollmentsCount"
        :origin="courseOffer.origin"
        :planning-status="courseOffer.planningStatus"
        @delete-offer="deleteCourseOffer(courseOffer.id)"
      ></offer-delete-button>
    </n-toolbar>
    <care-warning-banner-and-modal
      v-if="legacyEnrollmentWarning || isOnsiteOffer"
      :is-onsite-offer="isOnsiteOffer"
    />
    <div class="flex flex-row w-full">
      <div class="flex-1">
        <p class="font-bold uppercase mb-5 mt-12 text-blue-600">
          Angebotsinformationen
        </p>
        <h1 class="font-bold uppercase mb-3 text-2xl text-black">
          {{ courseOffer.name }}
        </h1>

        <div class="grid grid-cols-2 gap-8 mt-4">
          <div>
            <h2 class="font-bold">Angebotszeitraum</h2>
            <p class="text-gray-700">
              {{ yearSectionWithYearFormatter(courseOffer) }}
            </p>
          </div>

          <div>
            <h2 class="font-bold">Lehrform</h2>
            <p class="text-gray-700">
              {{ teachingFormatFormatter(courseOffer.teachingFormat) }}
            </p>
          </div>

          <div>
            <h2 class="font-bold">Herkunft</h2>
            <p class="text-gray-700">
              <a
                v-if="isCareOriginated && careBaseUrl"
                :href="careCourseOfferDeeplink"
                target="_blank"
                class="flex items-center"
              >
                Care
                {{
                  courseOffer.isSyncedWithCare
                    ? '(synchronisiert)'
                    : '(desynchronisiert)'
                }}
                <n-icon class="ml-1 text-black" icon="open-in-new" />
              </a>
              <span v-else>{{ originFormatter(courseOffer) }}</span>
            </p>
          </div>
        </div>
        <product-information-section :product="courseOffer.courseMainVersion">
          <template #productNameTitle>Name des Fachs</template>
          <template #productCodeTitle>Fachkürzel</template>
          <template #productVersionCodeTitle>Fachversion</template>
          <template #productECTSTitle>ECTS</template>
        </product-information-section>
      </div>
      <div class="w-96 ml-56">
        <div class="bg-bluegray p-5 mt-24 rounded">
          <div class="grid grid-cols-2 gap-4">
            <div class="flex justify-center">
              <img
                :src="crossLeggedImage"
                alt="Cross legged person"
                class="w-auto h-24"
              />
            </div>
            <div>
              <h2 class="font-bold mb-2">Teilnehmer</h2>
              <n-icon
                v-if="isOnsiteOffer"
                class="icon-4xl text-orange-600"
                icon="alert"
              />
              <attendee-visualization
                v-else
                :current-value="courseOffer.enrollmentsCount"
                :max-value="courseOffer.maxParticipants"
              />
            </div>
          </div>
          <div
            class="border-t border-blue-300 pt-5 mt-4 grid grid-cols-2 gap-4"
          >
            <div class="text-center" :class="isOnsiteOffer && 'invisible'">
              <div class="flex items-center justify-center">
                <n-icon
                  :class="isEnrollable ? 'text-green-600' : 'text-gray-700'"
                  :icon="isEnrollable ? 'mdi-check' : 'mdi-close'"
                  class="icon-4xl"
                />
                <n-icon
                  v-if="legacyEnrollmentWarning"
                  class="text-orange-600 icon-3xl"
                  icon="alert"
                />
              </div>

              <p class="text-blue-300 font-bold text-xs">
                <template v-if="isEnrollable">
                  Einschreibung<br />möglich
                </template>
                <template v-else>
                  Einschreibung <br />
                  nicht möglich
                </template>
              </p>
            </div>
            <div>
              <h2 class="font-bold mb-2">Einschreibung</h2>
              <n-icon
                v-if="isOnsiteOffer"
                class="text-orange-600 icon-4xl"
                icon="alert"
              />
              <template v-else>
                <template v-if="enrollmentPermanent">
                  <p class="text-xs text-gray-700">
                    <span class="font-bold text-blue-300 flex items-center">
                      <n-icon icon="infinity" />
                      <span class="ml-2">unbegrenzt</span>
                    </span>
                    Keine Einschreibefrist
                  </p>
                </template>
                <template v-else>
                  <p class="text-gray-700 text-xs">
                    <span class="font-bold text-blue-300">Start:</span>
                    {{ shortDate(courseOffer.enrollmentStartDate) }}
                  </p>
                  <p class="text-gray-700 text-xs">
                    <span class="font-bold text-blue-300">Ende:</span>
                    {{ shortDate(courseOffer.enrollmentEndDate) }}
                  </p>
                </template>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="font-bold uppercase mb-5 mt-20 text-blue-600">
      Zuordnung Lehrende
    </p>
    <enhanced-academic-staff-assignments-table
      :is-loading="isLoadingAcademicStaffAssignments"
      :data="academicStaffAssignments"
      :is-offer-desynchronized="isCourseOfferEditable"
      @delete="deleteAcademicStaffAssignment"
      @edit-assignment="openAcademicStaffAssignmentModal"
    />
    <template v-if="invalidAcademicStaffAssignments.length > 0">
      <p class="font-bold uppercase mb-5 mt-20 text-blue-600">
        Nicht zuordenbare Lehrende
      </p>
      <enhanced-invalid-academic-staff-assignments-table
        :is-loading="isLoadingInvalidAcademicStaffAssignments"
        :data="invalidAcademicStaffAssignments"
      />
    </template>

    <academic-staff-assignment-modal
      ref="assignmentModal"
      :academic-staff-profiles="academicStaffProfilesAsValueLabel"
      :is-saving="isLoadingAcademicStaffAssignments"
      @save="academicStaffAssignmentSaved"
      @update:profile-search="onAcademicStaffProfilesSearch"
    ></academic-staff-assignment-modal>

    <planning-status-tranistion-confirmation-modal
      v-if="!isOnsiteOffer"
      :value="transitionConfirmationModal"
      :requested-planning-status="requestedPlanningStatus"
      :current-planning-status="courseOffer.planningStatus"
      :course-offer-id="courseOffer.id"
      product-name="Fachangebot"
      @close="closeTransitionConfirmationDialog"
      @publish="publish"
      @republish="republish"
      @unpublish="unpublish"
      @withdraw="withdraw"
    />
  </view-wrapper>
  <view-wrapper v-else>
    <div class="flex flex-col items-center mt-12">
      <n-simple-loader />
      <p class="text-cta">Fachangebot wird geladen...</p>
    </div>
  </view-wrapper>
</template>

<script>
import {
  NIcon,
  NToolbar,
  NToolbarButton,
  NTooltip,
  NSimpleLoader,
} from '@careerpartner/nitro';
import { mapFields } from 'vuex-map-fields';
import { mapActions, mapGetters } from 'vuex';
import { IMAGE_URLS, FEATURE_FLAGS } from '@/constants';
import ViewWrapper from '@/components/ViewWrapper';
import PlanningStatusChip from '@/components/PlanningStatusChip.vue';
import CareWarningBannerAndModal from '@/components/CareWarningBannerAndModal';
import EnhancedAcademicStaffAssignmentsTable from '@/components/EnhancedAcademicStaffAssignmentsTable.vue';
import EnhancedInvalidAcademicStaffAssignmentsTable from '@/components/EnhancedInvalidAcademicStaffAssignmentsTable.vue';
import AcademicStaffAssignmentModal from '@/components/AcademicStaffAssignmentModal';
import { hasConflictingBookingConstraints } from '@/utils/hasConflictingBookingConstraint';
import { hasPermissionFor } from '@/utils/permissionUtil';
import { isFeatureEnabled } from '@/utils/feature';
import PlanningStatusButton from '@/components/PlanningStatusButton.vue';
import PlanningStatusTranistionConfirmationModal from '@/components/PlanningStatusTransitionConfirmationModal';
import { PLANNING_STATUS } from '@/constants/planning-status';
import AttendeeVisualization from '../../components/AttendeeVisualization.vue';
import OfferDeleteButton from '@/components/OfferDeleteButton.vue';
import ProductInformationSection from '@/components/ProductInformationSection.vue';
import { sanitizeUrl } from '@braintree/sanitize-url';
import urlJoin from 'url-join';
import { OFFER_ORIGIN } from '@/constants/offerOrigin';
import { MIXINS } from '@/mixins';
import { debounce } from '@/utils/debounce';

export default {
  name: 'CourseOfferDetailView',
  components: {
    ProductInformationSection,
    ViewWrapper,
    NIcon,
    NToolbarButton,
    NToolbar,
    NTooltip,
    PlanningStatusChip,
    CareWarningBannerAndModal,
    EnhancedAcademicStaffAssignmentsTable,
    EnhancedInvalidAcademicStaffAssignmentsTable,
    AcademicStaffAssignmentModal,
    NSimpleLoader,
    PlanningStatusButton,
    PlanningStatusTranistionConfirmationModal,
    AttendeeVisualization,
    OfferDeleteButton,
  },
  mixins: [MIXINS],

  beforeRouteEnter(to, _from, next) {
    if (!to.params.courseOfferId) return;
    const { courseOfferId } = to.params;
    next(async (vm) => {
      await Promise.all([
        vm.loadCourseOffer(courseOfferId),
        vm.loadCourseOfferEditability(courseOfferId),
      ]);
    });
  },

  data() {
    return {
      possiblePlanningStatus: null,
      transitionConfirmationModal: false,
      requestedPlanningStatus: null,
    };
  },

  computed: {
    ...mapFields('courseOffer', [
      'loading',
      'courseOffer',
      'courseOfferEditable',
      'academicStaffAssignments',
      'isLoadingAcademicStaffAssignments',
      'invalidAcademicStaffAssignments',
      'isLoadingInvalidAcademicStaffAssignments',
    ]),
    ...mapGetters('courseOffer', ['academicStaffProfilesAsValueLabel']),

    crossLeggedImage() {
      return IMAGE_URLS.CROSS_LEGGED;
    },

    isEnrollable() {
      return this.enrollmentStateFormatter(this.courseOffer.enrollmentState);
    },

    legacyEnrollmentWarning() {
      return hasConflictingBookingConstraints(this.courseOffer);
    },

    enrollmentPermanent() {
      return (
        !this.courseOffer.enrollmentEndDate &&
        !this.courseOffer.enrollmentStartDate
      );
    },

    maxParticipantsUnlimited() {
      return this.courseOffer.maxParticipants === null;
    },

    isCourseOfferEditable() {
      return !!this.courseOfferEditable;
    },

    userCanEditCourseOffer() {
      return hasPermissionFor('course-offer', ['u']);
    },

    userCanCreateAcademicStaffAssignment() {
      return hasPermissionFor('academic-staff-assignment', ['c']);
    },

    isWriteUIEnabled() {
      return isFeatureEnabled(FEATURE_FLAGS.WRITE_UI_ENABLED);
    },
    isOnsiteOffer() {
      return !this.courseOffer.isDistanceLearningOffer;
    },

    userCanChangePlanningStatus() {
      if (!this.possiblePlanningStatus) {
        return false;
      }

      switch (this.possiblePlanningStatus) {
        case PLANNING_STATUS.PUBLISHED:
          // if the current planning status is withdrawn this need republish permission
          if (this.courseOffer.planningStatus === PLANNING_STATUS.WITHDRAWN) {
            return hasPermissionFor('republish-offer', ['x']);
          }
          return hasPermissionFor('publish-offer', ['x']);
        case PLANNING_STATUS.DRAFT:
          return hasPermissionFor('unpublish-offer', ['x']);
        case PLANNING_STATUS.WITHDRAWN:
          return hasPermissionFor('withdraw-offer', ['x']);
      }

      return false;
    },

    editableTooltipContent() {
      if (!this.userCanEditCourseOffer) {
        return 'Du verfügst nicht über die erforderlichen Rechte, um Fachangebote zu bearbeiten';
      } else if (!this.isCourseOfferEditable) {
        return 'Dieses Fachangebot muss weiterhin in Care verwaltet werden';
      }

      return '';
    },
    isCareOriginated() {
      return this.courseOffer.origin === OFFER_ORIGIN.CARE;
    },
    careBaseUrl() {
      return PARAMETER_STORE.CARE_BASE_URL;
    },
    careCourseOfferDeeplink() {
      return sanitizeUrl(
        urlJoin(
          this.careBaseUrl,
          `/home#/course/${this.courseOffer.careOfferId}/edit`
        )
      );
    },
  },

  async mounted() {
    // this param is already checked in beforeRouteEnter so no need to recheck
    const { courseOfferId } = this.$route.params;
    if (this.isWriteUIEnabled) {
      await this.determineNextPossibleTransition(courseOfferId);
    }
    await this.getAcademicStaffAssignments(courseOfferId);
    await this.getAcademicStaffProfiles();

    if (!this.isCourseOfferEditable) {
      await this.getInvalidAcademicStaffAssignments(courseOfferId);
    }
  },

  methods: {
    ...mapActions('courseOffer', [
      'loadCourseOffer',
      'loadCourseOfferEditability',
      'getAcademicStaffAssignments',
      'getInvalidAcademicStaffAssignments',
      'createAcademicStaffAssignment',
      'updateAcademicStaffAssignmentPeriod',
      'deleteAcademicStaffAssignment',
      'getAcademicStaffProfiles',
      'getPossibleTransitions',
      'publishCourseOffer',
      'republishCourseOffer',
      'unpublishCourseOffer',
      'withdrawCourseOffer',
      'deleteCourseOffer',
    ]),

    onAcademicStaffProfilesSearch: debounce(function (searchTerm) {
      if (searchTerm) {
        this.getAcademicStaffProfiles(searchTerm);
      }
    }, 500),

    openAcademicStaffAssignmentModal(assignment) {
      this.$refs.assignmentModal.openModal(assignment);
    },

    async openCourseOfferEdit(courseOfferId) {
      await this.$router.push({
        name: 'course-offer-update',
        params: { courseOfferId: courseOfferId },
      });
    },

    async academicStaffAssignmentSaved(academicStaffAssignment) {
      if (academicStaffAssignment.id) {
        const { id, role, assignmentStartDate, assignmentEndDate } =
          academicStaffAssignment;

        await this.updateAcademicStaffAssignmentPeriod({
          id,
          role,
          assignmentStartDate,
          assignmentEndDate,
        });
      } else {
        // eslint-disable-next-line no-unused-vars
        const { id, ...rest } = academicStaffAssignment;
        await this.createAcademicStaffAssignment(rest);
      }

      await this.getAcademicStaffAssignments(this.courseOffer.id);
      this.$refs.assignmentModal.closeModal();
    },

    async determineNextPossibleTransition(courseOfferId) {
      if (!courseOfferId) {
        return;
      }

      this.possiblePlanningStatus = (
        await this.getPossibleTransitions(courseOfferId)
      ).data.nextTransition;
    },

    openTransitionConfirmationDialog(requestedPlanningStatus) {
      this.requestedPlanningStatus = requestedPlanningStatus;
      this.transitionConfirmationModal = true;
    },

    closeTransitionConfirmationDialog() {
      this.requestedPlanningStatus = null;
      this.transitionConfirmationModal = false;
    },

    async publish() {
      await this.publishCourseOffer(this.courseOffer.id);
      await this.refreshDetailsPage(this.courseOffer.id);
    },

    async republish() {
      await this.republishCourseOffer(this.courseOffer.id);
      await this.refreshDetailsPage(this.courseOffer.id);
    },

    async unpublish() {
      await this.unpublishCourseOffer(this.courseOffer.id);
      await this.refreshDetailsPage(this.courseOffer.id);
    },

    async withdraw() {
      await this.withdrawCourseOffer(this.courseOffer.id);
      await this.refreshDetailsPage(this.courseOffer.id);
    },

    async refreshDetailsPage(courseOfferId) {
      await this.loadCourseOffer(courseOfferId);

      if (this.isWriteUIEnabled) {
        await this.determineNextPossibleTransition(courseOfferId);
      }

      await this.getAcademicStaffAssignments(courseOfferId);
      await this.getInvalidAcademicStaffAssignments(courseOfferId);
      await this.getAcademicStaffProfiles();
    },
  },
};
</script>
