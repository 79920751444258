<template>
  <view-wrapper>
    <n-toolbar v-if="isEditingEnabled">
      <n-toolbar-button
        :disabled="!userCanCreateModuleOffer"
        text="Neues Modulangebot anlegen"
        icon="mdi-plus"
        disabled-text="Du verfügst nicht über die erforderlichen Rechte, um Modulangebote anzulegen"
        :to="{ name: 'module-offer-create' }"
      />
    </n-toolbar>
    <list-search-bar
      class="mb-16 mt-12"
      :value="searchTerm"
      label="Suche nach Modulkürzel, Modulname oder Name des Modulangebotes"
      @search="onSearchChanged"
    />
    <list-filter-bar
      v-if="hasFilters"
      class="mb-8"
      :filters="getFilter"
      @reset="onFilterReset"
    />
    <module-offer-table
      :data="searchResult"
      :is-search="searchTerm.length > 0"
      :is-loading="isLoading"
      :total-count="pagination.totalCount"
      @filter="onFilterChanged"
      @reset="onFilterReset"
      @sort="onSort"
    />
    <n-pagination
      v-if="pagination.totalCount > 0"
      class="mt-16"
      :model-value="pagination"
      @update:model-value="onPaginationChanged"
    />
  </view-wrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { NPagination, NToolbar, NToolbarButton } from '@careerpartner/nitro';
import ViewWrapper from '@/components/ViewWrapper';
import ModuleOfferTable from '@/components/ModuleOfferTable.vue';
import ListSearchBar from '@/components/ListSearchBar.vue';
import ListFilterBar from '@/components/ListFilterBar.vue';
import { isFeatureEnabled } from '@/utils/feature';
import { FEATURE_FLAGS } from '@/constants';
import { getDefaultSortOrder } from '@/constants/sortOrder';
import { hasPermissionFor } from '@/utils/permissionUtil';

export default {
  name: 'ModuleOfferListView',

  components: {
    ViewWrapper,
    ModuleOfferTable,
    ListSearchBar,
    NPagination,
    NToolbar,
    ListFilterBar,
    NToolbarButton,
  },
  computed: {
    ...mapState('moduleOfferSearch', [
      'isLoading',
      'searchResult',
      'pagination',
      'filter',
    ]),
    ...mapGetters('moduleOfferSearch', ['hasFilters', 'getFilter']),

    searchTerm() {
      return this.filter?.search ?? '';
    },

    isEditingEnabled() {
      return isFeatureEnabled(FEATURE_FLAGS.WRITE_UI_ENABLED);
    },

    userCanCreateModuleOffer() {
      return hasPermissionFor('module-offer', ['c']);
    },
  },

  watch: {
    $route: {
      immediate: true,
      async handler(route) {
        const query = route.query || {};

        if (!query.key) {
          const updatedQuery = {
            ...query,
            ...getDefaultSortOrder('moduleOffer'),
            status: 'KNOWN',
          };

          await this.$router.push({
            query: updatedQuery,
          });
        }

        if (JSON.stringify(query) === '{}') {
          return;
        }

        await this.search(query);
      },
    },
  },
  async created() {
    await this.setDefaultQuery();
  },

  methods: {
    ...mapActions('moduleOfferSearch', ['search']),
    async setDefaultQuery() {
      const query = this.$route.query;

      if (!query.key || !query.status) {
        const updatedQuery = {
          ...query,
          ...getDefaultSortOrder('moduleOffer'),
          status: 'KNOWN',
        };

        await this.$router.push({
          query: updatedQuery,
        });

        return updatedQuery;
      }

      return query;
    },
    transformToOrFilter(query, ...filters) {
      filters.forEach((filter) => {
        const val = query[filter];
        if (Array.isArray(val) && val.length) {
          query[filter] = val.join(',');
        } else if (typeof val !== 'string') {
          delete query[filter];
        }
      });
    },

    async onPaginationChanged(pagination) {
      const { limit, offset } = pagination;

      await this.$router.push({
        ...this.$route,
        query: { ...this.$route.query, limit, offset },
      });
    },

    async onSearchChanged(search) {
      await this.$router.push({
        ...this.$route,
        query: { ...this.$route.query, search, offset: 0 },
      });
    },

    async onFilterChanged(filter) {
      const query = Object.assign({}, this.$route.query, filter);

      this.transformToOrFilter(query, 'status');

      await this.$router.push({
        ...this.$route,
        query: {
          ...query,
          offset: 0,
        },
      });
    },

    async onFilterReset(filterName) {
      if (filterName) {
        const query = Object.assign({}, this.$route.query, { offset: 0 });
        delete query[filterName];
        await this.$router.push({
          ...this.$route,
          query,
        });
      } else {
        await this.$router.push({
          ...this.$route,
          query: {},
        });
      }
    },
    async onSort(sort) {
      if (!sort) {
        await this.onFilterReset('key');
        await this.onFilterReset('order');
        return;
      }
      await this.$router.push({
        ...this.$route,
        query: { ...this.$route.query, ...sort, offset: 0 },
      });
    },
  },
};
</script>
