import { getField, updateField } from 'vuex-map-fields';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import state from './state';

export default {
  namespaced: true,
  state,
  actions,
  getters: {
    getField,
    ...getters,
  },
  mutations: {
    updateField,
    ...mutations,
  },
};
