<template>
  <div>
    <no-product-assigned-card
      v-if="!assignedCourse"
      type="Fach"
      @open-assignment-clicked="openModal"
    />

    <course-assigned-card
      v-else
      :assigned-course="assignedCourse"
      :disabled="disabled"
      @click="resetAndOpenModal"
    />

    <n-modal
      :is-open="isModalOpen"
      title="Auswahl eines Fachs"
      large-deprecated
      @close="closeModal"
    >
      <div class="overflow-hidden relative pt-9 px-6 pb-6">
        <list-search-bar
          label="Suche nach Fachname oder Fachkürzel"
          :value="searchTerm"
          @search="onSearchChanged"
        />
        <n-table
          :data="searchResult"
          :header-items="headerItems"
          :is-loading="isLoading"
        >
          <template #row="{ item }">
            <n-table-row :item="item">
              <td>
                <div class="flex">
                  <input
                    :id="item.courseMainVersionCode"
                    v-model="course"
                    type="radio"
                    name="assignedCourse"
                    :aria-label="item.name"
                    :value="item"
                  />
                  <label
                    class="block w-full pl-2"
                    :for="item.courseMainVersionCode"
                  >
                    {{ item.name }}
                  </label>
                </div>
              </td>
              <td>{{ item.courseMainVersionCode }}</td>
              <td>{{ item.versionCode }}</td>
              <td>{{ item.ects }}</td>
            </n-table-row>
          </template>

          <template #no-results>
            <n-empty-results
              v-if="isSearch"
              text="Keine Kurse gefunden"
              reset-text="Suche & Filter zurücksetzen"
              @reset="resetFilter"
            />
            <n-empty-data v-else text="Keine Kurse vorhanden" />
          </template>
        </n-table>

        <n-pagination
          v-if="pagination.totalCount > 0"
          :limit-options="[]"
          :model-value="pagination"
          class="mt-8"
          @update:model-value="onPaginationChanged($event)"
        />
      </div>
      <template #actions>
        <div class="mt-12 flex justify-end">
          <n-button :disabled="!course" @click="save"> Zuordnen </n-button>
        </div>
      </template>
    </n-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { NButton, NModal, NPagination, NTable } from '@careerpartner/nitro';
import NoProductAssignedCard from '@/components/NoProductAssignedCard';
import CourseAssignedCard from '@/components/CourseAssignedCard';
import ListSearchBar from '@/components/ListSearchBar';

export default {
  name: 'CourseOfferCourseAssignment',
  components: {
    NButton,
    NModal,
    NPagination,
    NTable,
    NoProductAssignedCard,
    CourseAssignedCard,
    ListSearchBar,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    assignedCourse: {
      type: Object,
      default: null,
    },
  },
  emits: ['courseSelected'],
  data: () => ({
    isModalOpen: false,
    searchTerm: '',
    course: null,
    headerItems: [
      {
        class: 'w-7/12 px-3',
        label: 'Name des Fachs',
        key: 'name',
      },
      {
        class: 'w-2/12 px-3',
        label: 'Fachkürzel',
        key: 'courseCode',
      },
      {
        class: 'w-2/12 px-3',
        label: 'Fachversion',
        key: 'versionCode',
      },
      {
        class: 'w-1/12 px-3',
        label: 'ECTS',
        key: 'ects',
      },
    ],
  }),
  computed: {
    ...mapState('courseSearch', [
      'isLoading',
      'searchResult',
      'pagination',
      'filter',
    ]),
    isSearch() {
      return !!this.filter.search;
    },
  },
  watch: {
    assignedCourse(newAssignment) {
      this.course = newAssignment;
    },
  },
  methods: {
    ...mapActions('courseSearch', ['search']),

    async onPaginationChanged(pagination) {
      this.course = null;

      const { limit, offset } = pagination;
      await this.search({ limit, offset, search: this.searchTerm });
    },

    async onSearchChanged(search) {
      this.course = null;

      this.searchTerm = search;
      await this.search({ limit: 5, offset: 0, search });
    },

    async onFilterReset() {
      this.searchTerm = '';
      await this.search({ limit: 5, offset: 0 });
    },

    save() {
      this.isModalOpen = false;

      this.$emit('courseSelected', this.course);
    },

    async openModal() {
      this.isModalOpen = true;

      await this.onFilterReset();
    },

    async resetAndOpenModal() {
      this.course = null;

      await this.openModal();
    },

    closeModal() {
      this.course = null;

      this.isModalOpen = false;
    },

    resetFilter() {
      this.onFilterReset();
    },
  },
};
</script>
