<template>
  <validation-provider
    v-slot="{ errors, field }"
    name="name"
    label="Name des Modulangebots"
    :rules="{ required: true, max: 255, min: 3 }"
  >
    <n-input
      id="module-offer-name"
      v-model="offerFormData.name"
      class="col-span-full"
      v-bind="field"
      name="name"
      label="Name des Modulangebots"
      required
      :errors="errors"
      @update:model-value="removeLeadingWhiteSpace($event)"
    />
  </validation-provider>

  <div class="col-span-full">
    <div>Angebotszeitraum*</div>
    <div class="flex justify-between mt-2">
      <n-radio-group
        v-model="yearSectionEnabled"
        :options="YEAR_SECTION.options"
        name="year-section"
        class="w-1/4"
      >
        <template #default="{ options }">
          <div
            v-for="(item, index) in options"
            :key="index"
            class="flex items-center"
          >
            <n-radio-button v-model="yearSectionEnabled" :value="item.value">
              {{ item.label }}
            </n-radio-button>
          </div>
        </template>
      </n-radio-group>

      <div class="w-1/3">
        <n-select
          v-if="!yearSectionEnabled"
          id="module-offer-year"
          v-model="offerFormData.year"
          label="Jahr"
          name="year"
          disabled
          :options="yearsOptions"
          option-label="label"
          track-by="value"
          mapped
        />
        <validation-provider
          v-else
          v-slot="{ errors, field }"
          name="year"
          label="Jahr"
          rules="required"
        >
          <n-select
            id="module-offer-year"
            v-model="offerFormData.year"
            label="Jahr"
            name="year"
            :errors="errors"
            v-bind="field"
            :disabled="!yearSectionEnabled"
            :options="yearsOptions"
            option-label="label"
            track-by="value"
            mapped
            required
          />
        </validation-provider>
      </div>

      <div class="w-1/3">
        <n-select
          v-if="!yearSectionEnabled"
          id="module-offer-year-section"
          label="Semester / Quartal"
          name="yearSection"
          :options="yearSectionOptions"
          disabled
        />
        <validation-provider
          v-else
          v-slot="{ errors, field }"
          name="yearSection"
          label="Semester / Quartal"
          rules="required"
        >
          <n-select
            id="module-offer-year-section"
            v-model="offerFormData.yearSection"
            label="Semester / Quartal"
            name="yearSection"
            v-bind="field"
            :errors="errors"
            option-label="label"
            track-by="value"
            required
            :options="yearSectionOptions"
          />
        </validation-provider>
      </div>
    </div>
  </div>
</template>

<script>
import { NInput, NRadioGroup } from '@careerpartner/nitro';
import { MIXINS } from '@/mixins';
import moduleOffer from '@/store/modules/module-offer';
import isEqual from 'lodash/isEqual';

const YEAR_SECTION = Object.freeze({
  options: [
    { value: false, label: 'permanent' },
    { value: true, label: '' },
  ],
});

export default {
  name: 'ModuleOfferInformationForm',
  components: {
    NInput,
    NRadioGroup,
  },
  mixins: [MIXINS],
  props: {
    modelValue: {
      type: Object,
      default: () => ({
        name: null,
        year: null,
        yearSection: null,
      }),
    },
  },
  emits: ['update:model-value', 'hasChanged'],
  data() {
    return {
      YEAR_SECTION,
      offerFormData: this.modelValue,
      yearSectionEnabled: !!this.modelValue.yearSection,
      initialValue: {},
      currentValue: {},
    };
  },
  computed: {
    yearsOptions() {
      const yearMinDate = (new Date().getFullYear() - 10).toString();
      const yearMaxDate = (new Date().getFullYear() + 10).toString();
      const options = [];
      let currentYear = new Date(yearMinDate).getFullYear();

      while (currentYear <= new Date(yearMaxDate).getFullYear()) {
        options.push(currentYear.toString());
        currentYear += 1;
      }

      return options.map((opt) => {
        return { label: opt, value: opt };
      });
    },
  },
  watch: {
    yearSectionEnabled(currentVal) {
      this.currentValue.yearSectionEnabled = currentVal;
      if (!currentVal) {
        this.offerFormData = {
          ...this.offerFormData,
          year: null,
          yearSection: null,
        };
      }
    },

    offerFormData: {
      handler: function (newVal) {
        this.currentValue = { ...this.currentValue, ...newVal };
        this.$emit('update:model-value', newVal);
      },
      deep: true,
    },
    currentValue: {
      deep: true,
      handler(internalValue) {
        this.$emit('hasChanged', !isEqual(internalValue, this.initialValue));
      },
    },
  },
  mounted() {
    this.initialValue = _.cloneDeep({
      ...this.offerFormData,
      yearSectionEnabled: this.yearSectionEnabled,
    });
    this.currentValue = _.cloneDeep(this.initialValue);
  },
  methods: {
    moduleOffer() {
      return moduleOffer;
    },
    removeLeadingWhiteSpace(value) {
      this.offerFormData.name = value.trimStart();
    },
  },
};
</script>
