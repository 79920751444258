<template>
  <div>
    <div
      class="bg-orange-50 py-3 pl-8 pr-6 relative text-orange-600 rounded overflow-hidden text-xs flex"
    >
      <div>
        <div class="absolute left-0 top-0 bottom-0 bg-orange-600 w-2"></div>
        <p class="flex items-center font-bold">
          <n-icon icon="alert-outline" /><span class="ml-1">Warnung</span>
        </p>
        <p v-if="isOnsiteOffer">
          Dieses Angebot wurde noch nicht vollständig migriert. Informationen,
          welche mit einem orangenen Ausrufezeichen markiert sind, können daher
          nicht angezeigt werden.
        </p>
        <p v-else>
          Blockierende CARE-Konfigurationen verhindern Einschreibung.
          Einstellungen müssen in CARE angepasst werden.
        </p>
      </div>
      <div v-if="!isOnsiteOffer" class="ml-auto flex items-center">
        <n-button variant="tertiary" class="text-orange-600" @click="openModal">
          Mehr erfahren
        </n-button>
      </div>
    </div>

    <n-dialog
      :is-open="isModalOpen"
      :image="lockedWarning"
      :subject="dialogSubject"
      :description="dialogDescription"
      size="lg"
      extended
      @close="closeModal"
    >
      <template #image>
        <img :src="lockedWarning" />
      </template>

      <template #default>
        <div
          class="flex flex-1 flex-col items-center justify-center p-8 overflow-y-auto text-left"
        >
          <div class="bg-blue-100 p-5 mt-8 border-t border-gray-700">
            <ul>
              <li class="flex mb-1 items-center">
                <n-icon icon="alert" class="text-orange-600" />
                <p class="ml-2 text-xs">
                  Es ist
                  <strong>kein Einschreibefrist-Element</strong> zugewiesen
                </p>
              </li>
              <li class="flex mb-1">
                <n-icon icon="alert" class="text-orange-600" />
                <p class="ml-2 text-xs">
                  <strong>Keins</strong> der zugewiesenen
                  Einschreibefrist-Elemente hat einen
                  <strong>Buchungsstatus</strong> hinterlegt
                </p>
              </li>
              <li class="flex mb-1">
                <n-icon icon="alert" class="text-orange-600" />
                <p class="ml-2 text-xs">
                  <strong>Keins</strong> der zugewiesenen
                  Einschreibefrist-Elemente hat den
                  <strong>Standort “Distance Learning”</strong> hinterlegt
                </p>
              </li>
            </ul>
          </div>
        </div>
      </template>
      <template #actions>
        <div class="flex w-full mt-12 justify-end">
          <n-button variant="primary" @click="closeModal">Okay</n-button>
        </div>
      </template>
    </n-dialog>
  </div>
</template>

<script>
import { NIcon, NButton, NDialog } from '@careerpartner/nitro';
import { IMAGE_URLS } from '@/constants';

export default {
  name: 'CareWarningBannerAndModal',

  components: { NIcon, NButton, NDialog },

  props: {
    isOnsiteOffer: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    isModalOpen: false,
  }),

  computed: {
    lockedWarning() {
      return IMAGE_URLS.LOCKED_WARNING;
    },
    dialogSubject() {
      return 'Einschreibung wegen unpassender CARE-Konfiguration bei Kurs-Einschreibefrist-Elementen nicht möglich';
    },
    dialogDescription() {
      return 'Eine Einschreibung ist nicht möglich, weil mindestens einer der folgenden Punkte in CARE angepasst werden muss:';
    },
  },

  methods: {
    openModal() {
      this.isModalOpen = true;
    },

    closeModal() {
      this.isModalOpen = false;
    },
  },
};
</script>
