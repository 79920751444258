<template>
  <div
    class="w-64 relative border-2 border-cta rounded-md flex flex-col ring-2 ring-cta-lighter py-7 px-8"
  >
    <div
      class="absolute top-3 right-3 bg-cta rounded-xl w-5 h-5 flex justify-center items-center self-end"
    >
      <n-icon icon="check" size="md" class="text-white" />
    </div>
    <div class="flex items-center space-x-4">
      <img
        :src="openBookImage"
        alt="Open book"
        class="w-16 object-cover flex flex-col"
      />
      <span class="font-semibold text-cta font-bold">{{ text }}</span>
    </div>
  </div>
</template>
<script>
import { NIcon } from '@careerpartner/nitro';
import { IMAGE_URLS } from '@/constants';
export default {
  name: 'OfferType',
  components: { NIcon },
  props: {
    text: {
      type: String,
      required: true,
    },
  },

  computed: {
    openBookImage() {
      return IMAGE_URLS.OPEN_BOOK;
    },
  },
};
</script>
