import { isFeatureEnabled } from '@/utils/feature';
import { hasPermissionFor } from '@/utils/permissionUtil';

const beforeEnterFeatureGuard = (featureFlag, fallbackURL, next) => {
  if (!isFeatureEnabled(featureFlag)) {
    return next(fallbackURL);
  }
};

const beforeEnterPermissionGuard = (resourcePath, actions, next) => {
  if (!hasPermissionFor(resourcePath, actions)) {
    return next({ name: 'Forbidden403' });
  }
};

export { beforeEnterPermissionGuard, beforeEnterFeatureGuard };
