<template>
  <div
    :class="{ 'bg-gray-100': disabled, 'bg-white': !disabled }"
    class="relative border border-gray-700 rounded py-4 pl-7 pr-11 flex flex-row"
  >
    <div class="flex flex-col flex-1 mr-4">
      <div class="mb-3">
        <p>
          <span class="block font-bold text-black">Name des Moduls</span>
          <span class="block text-gray-700">
            {{ assignedModule.defaultTitle }}
          </span>
        </p>
      </div>
      <div>
        <p>
          <span class="block font-bold text-black">Modulversion</span>
          <span class="block text-gray-700">
            {{ assignedModule.moduleMainVersionCode }}
          </span>
        </p>
      </div>
    </div>
    <div class="flex flex-col">
      <div class="mb-3">
        <p>
          <span class="block font-bold text-black">Modulkürzel</span>
          <span class="block text-gray-700">
            {{ assignedModule.moduleCode }}
          </span>
        </p>
      </div>
      <div>
        <p>
          <span class="block font-bold text-black">ECTS</span>
          <span class="block text-gray-700"> {{ assignedModule.ects }}</span>
        </p>
      </div>
    </div>

    <n-tooltip content="bearbeiten" class="absolute top-0 -right-10">
      <n-button v-if="!disabled" variant="tertiary" @click="onButtonClick">
        <n-icon icon="pencil-outline" />
      </n-button>
    </n-tooltip>
  </div>
</template>

<script>
import { NButton, NIcon, NTooltip } from '@careerpartner/nitro';

export default {
  name: 'ModuleAssignedCard',

  components: {
    NButton,
    NIcon,
    NTooltip,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    assignedModule: {
      type: Object,
      default: null,
    },
  },
  emits: ['click'],

  methods: {
    onButtonClick() {
      this.$emit('click');
    },
  },
};
</script>
