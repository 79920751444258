<template>
  <td class="text-black font-bold">
    <div class="ml-6 truncate">
      <span class="text-xs">
        {{ teachingFormatFormatter(item.teachingFormat) }}
      </span>
      <br />
      <router-link
        :to="{
          name: 'course-offer-detail',
          params: { courseOfferId: item.id },
        }"
      >
        <span :title="item.name">{{ item.name }}</span>
      </router-link>
    </div>
  </td>
  <td class="text-black">
    <span>
      <span class="font-bold">
        {{ item.courseMainVersion?.courseCode }}
      </span>
      <div class="text-cta inline-flex">
        <n-tooltip :content="item.courseMainVersion?.defaultTitle">
          <n-icon icon="information-outline" />
        </n-tooltip>
      </div>
    </span>
    <br />
    <div class="text-gray-700 text-xs truncate">
      <span :title="item.courseMainVersion?.courseMainVersionCode">
        Version: {{ item.courseMainVersion?.courseMainVersionCode }}
      </span>
    </div>
  </td>
  <td class="text-black">
    {{ yearSectionWithYearFormatter(item) }}
  </td>
  <td>
    <planning-status-chip :value="item.planningStatus" />
  </td>
  <td>
    <n-tooltip v-if="isOnsiteOffer" :content="onsiteOfferWarningText">
      <n-icon class="text-orange-600" icon="alert" />
    </n-tooltip>
    <div v-else>
      <enrollment-state-icon :value="item.enrollmentState" />
      <div v-if="constraints.hasConflictingBooking" class="inline-flex ml-2">
        <n-tooltip content="Blockierende CARE-Konfiguration">
          <n-icon class="text-orange-600" icon="alert" />
        </n-tooltip>
      </div>
    </div>
  </td>
  <td>
    {{ originFormatter(item) }}
  </td>
  <td>
    <n-tooltip v-if="isOnsiteOffer" :content="onsiteOfferWarningText">
      <n-icon class="text-orange-600" icon="alert" />
    </n-tooltip>
    <attendee-visualization
      v-else
      :current-value="item.enrollmentsCount"
      :max-value="item.maxParticipants"
    />
  </td>
</template>

<script>
import { NIcon, NTooltip } from '@careerpartner/nitro';
import PlanningStatusChip from '@/components/PlanningStatusChip';
import EnrollmentStateIcon from '@/components/EnrollmentStateIcon';
import AttendeeVisualization from '@/components/AttendeeVisualization';
import { MIXINS } from '@/mixins';

export default {
  name: 'CourseOfferTableRow',
  components: {
    AttendeeVisualization,
    NIcon,
    EnrollmentStateIcon,
    NTooltip,
    PlanningStatusChip,
  },

  mixins: [MIXINS],

  props: {
    item: {
      type: Object,
      required: true,
    },
    selectedId: {
      type: String,
      default: undefined,
    },
    constraints: {
      type: Object,
      required: true,
      validator: (value) => {
        return (
          Object.prototype.hasOwnProperty.call(
            value,
            'hasConflictingBooking'
          ) &&
          Object.prototype.hasOwnProperty.call(value, 'isFullyBooked') &&
          typeof value.hasConflictingBooking === 'boolean' &&
          typeof value.isFullyBooked === 'boolean'
        );
      },
    },
    isOnsiteOffer: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['select'],

  computed: {
    onsiteOfferWarningText() {
      return 'Wert liegt nicht vor. Angebot wurde noch nicht vollständig migriert';
    },
  },
};
</script>
