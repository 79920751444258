export const transformSearchParams = (filter, limit, offset, sort = null) => {
  return {
    params: {
      filter,
      limit,
      offset,
      sort,
    },
  };
};
