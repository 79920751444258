<template>
  <p class="text-right">
    Anzeige aller
    <span class="font-semibold text-primary">{{ totalCount }}</span>
    Kursangebote.
  </p>
  <n-table :header-items="headerItems" :data="data" :is-loading="isLoading">
    <template #th-name="{ item }">
      <span class="mr-2">{{ item.label }}</span>
      <column-sort-button
        id="course_offer_filter"
        column-name="courseOffer.name"
        @on-new-sort="onNewSort"
      />
    </template>
    <template #filter-name="{ close }">
      <n-table-filter-checkbox
        id="course-offer-table__filter-teaching-format"
        :options="filtersByTeachingFormatOptions"
        data-test="table-filter-by-teaching-format"
        :model-value="queryTeachingFormat"
        @update:model-value="$emit('filter', { teachingFormat: $event })"
        @close="close"
      />
    </template>
    <template #th-course="{ item }">
      <span class="mr-2">{{ item.label }}</span>
      <column-sort-button
        column-name="course.courseCode"
        @on-new-sort="onNewSort"
      />
    </template>
    <template #filter-time="{ close }">
      <n-table-filter-radio
        id="course-offer-table__filter-time"
        :options="filtersByTimeOptions"
        data-test="table-filter-by-time"
        :model-value="queryTime"
        @update:model-value="$emit('filter', { time: $event })"
        @close="close"
      />
    </template>
    <template #filter-status="{ close }">
      <n-table-filter-radio
        id="course-offer-table__filter-status"
        :options="filtersByStatusOptions"
        data-test="table-filter-by-status"
        :model-value="queryStatus"
        @update:model-value="$emit('filter', { status: $event })"
        @close="close"
      />
    </template>
    <template #filter-bookable="{ close }">
      <n-table-filter-radio
        id="course-offer-table__filter-bookable"
        :options="filtersByBookableOptions"
        data-test="table-filter-by-bookable"
        :model-value="queryBookable"
        @update:model-value="$emit('filter', { bookable: $event })"
        @close="close"
      />
    </template>
    <template #filter-enrollments="{ close }">
      <n-table-filter-radio
        id="course-offer-table__filter-enrollments"
        :options="filtersByEnrollmentsCount"
        data-test="table-filter-by-enrollments"
        :model-value="queryEnrollment"
        @update:model-value="$emit('filter', { enrollment: $event })"
        @close="close"
      />
    </template>
    <template #filter-origin="{ close }">
      <n-table-filter-radio
        id="course-offer-table__filter-origin"
        :options="filtersByOrigin"
        data-test="table-filter-by-origin"
        :model-value="queryOrigin"
        @update:model-value="$emit('filter', { origin: $event })"
        @close="close"
      />
    </template>
    <template #row="{ isExpanded, item }">
      <n-table-row
        :item="item"
        :class="[getConstraintClass(item)]"
        class="course-offer-table-row__data relative"
      >
        <course-offer-table-row
          :item="item"
          :selected-id="selectedId"
          :constraints="getConstraints(item)"
          :is-onsite-offer="isOnsiteOffer(item)"
        />
        <template #extended>
          <academic-staff-assignments-table
            v-if="isExpanded"
            :key="item.id"
            :offer="item"
          />
        </template>
      </n-table-row>
    </template>

    <template #no-results>
      <n-empty-results
        v-if="isSearch"
        text="Keine Suchergebnisse gefunden"
        reset-text="Suche & Filter zurücksetzen"
        @reset="resetFilter"
      />
      <n-empty-data v-else text="Keine Fachabgebote vorhanden" />
    </template>
  </n-table>
</template>

<script>
import {
  NTable,
  NTableFilterCheckbox,
  NTableFilterRadio,
} from '@careerpartner/nitro';
import { ENROLLMENT_STATUS } from '@/constants/enrollmentStatus';
import CourseOfferTableRow from '@/components/CourseOfferTableRow';
import ColumnSortButton from '@/components/ColumnSortButton.vue';
import { getDefaultSortOrder } from '@/constants/sortOrder';
import { determineCurrentSortOrder } from '@/utils/determineCurrentSortOrder';
import { OFFER_ORIGIN_FILTER } from '@/constants/offerOriginFilter';
import { hasConflictingBookingConstraints } from '@/utils/hasConflictingBookingConstraint';
import AcademicStaffAssignmentsTable from '@/components/AcademicStaffAssignmentsTable.vue';

export default {
  name: 'CourseOfferTable',

  components: {
    AcademicStaffAssignmentsTable,
    CourseOfferTableRow,
    NTable,
    NTableFilterCheckbox,
    NTableFilterRadio,
    ColumnSortButton,
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selectItem', 'reset', 'sort', 'filter'],

  data: () => ({
    headerItems: [
      {
        class: 'w-4/12 px-3',
        label: 'Fachangebot',
        key: 'name',
      },
      {
        class: 'w-2/12 px-3',
        label: 'Fach',
        key: 'course',
      },
      {
        class: 'w-2/12 px-3',
        label: 'Zeitraum',
        key: 'time',
      },
      {
        class: 'w-2/12 px-3',
        label: 'Status',
        key: 'status',
      },
      {
        class: 'w-2/12 px-3',
        label: 'Einschreibung',
        key: 'bookable',
      },
      {
        class: 'w-2/12 px-3',
        label: 'Herkunft',
        key: 'origin',
      },
      {
        class: 'w-2/12 px-3',
        label: 'Teilnehmer',
        key: 'enrollments',
      },
    ],
    filtersByStatusOptions: [
      { value: 'DRAFT', label: 'Entwurf' },
      { value: 'WITHDRAWN', label: 'Zurückgenommen' },
      { value: 'PUBLISHED', label: 'Veröffentlicht' },
      { value: 'UNKNOWN', label: 'Unbekannt' },
      { value: 'KNOWN', label: 'Nicht Unbekannt' },
    ],
    filtersByBookableOptions: [
      { value: 'true', label: 'Buchbar' },
      { value: 'false', label: 'Nicht buchbar' },
    ],
    filtersByTimeOptions: [
      { value: 'false', label: 'Permanent' },
      { value: 'true', label: 'Nicht permanent' },
    ],
    filtersByTeachingFormatOptions: [
      { value: 'PRAESENZ-VORLESUNGEN', label: 'Präsenz Vorlesung' },
      { value: 'ONLINE-SELBSTSTUDIUM', label: 'Online Selbststudium' },
      { value: 'THESIS', label: 'Thesis' },
      { value: 'PRAXIS-PROJEKT', label: 'Praxisprojekt' },
      { value: 'INTEGRIERTE-VORLESUNG', label: 'Integrierte Vorlesung' },
      { value: 'PRAXIS-REFLEKTION', label: 'Praxisreflektion' },
      { value: 'INTEGRIERTE-UEBUNG', label: 'Integrierte Übung' },
      { value: 'SEMINAR', label: 'Seminar' },
      { value: 'PRAXIS-SEMINAR', label: 'Praxisseminar' },
      { value: 'FACHCOACHING', label: 'Fachcoaching' },
      { value: 'TUTORIUM', label: 'Tutorium' },
    ],
    filtersByEnrollmentsCount: [
      { value: ENROLLMENT_STATUS.NONE, label: 'Keine Teilnehmer' },
      {
        value: ENROLLMENT_STATUS.HAS_ENROLLMENTS,
        label: 'Teilnehmer eingebucht',
      },
    ],
    filtersByOrigin: [
      { value: OFFER_ORIGIN_FILTER.EPOS, label: 'EPOS' },
      {
        value: OFFER_ORIGIN_FILTER.PRODUCT,
        label: 'IU Product Catalog',
      },
      {
        value: OFFER_ORIGIN_FILTER.CARE_SYNCHRONIZED,
        label: 'Care (synchronisiert)',
      },
      {
        value: OFFER_ORIGIN_FILTER.CARE_DESYNCHRONIZED,
        label: 'Care (desynchronisiert)',
      },
    ],
    selectedId: null,
    currentSort: getDefaultSortOrder('courseOffer'),
  }),

  computed: {
    queryTeachingFormat() {
      const { teachingFormat } = this.$route.query;

      if (teachingFormat) {
        if (Array.isArray(teachingFormat)) {
          return teachingFormat;
        } else {
          return teachingFormat.split(',');
        }
      }

      return [];
    },

    queryStatus() {
      const { status } = this.$route.query;

      if (status) return status;

      return 'KNOWN';
    },

    queryTime() {
      const { time } = this.$route.query;

      return time ?? '';
    },

    queryBookable() {
      const { bookable } = this.$route.query;

      return bookable ?? '';
    },

    queryEnrollment() {
      const { enrollment } = this.$route.query;

      return enrollment ?? '';
    },

    queryOrigin() {
      const { origin } = this.$route.query;

      return origin ?? '';
    },
  },

  mounted() {
    if (this.$route.query.key) {
      this.currentSort.key = this.$route.query.key;
      this.currentSort.order = this.$route.query.order;
    }
  },
  methods: {
    resetFilter() {
      this.$emit('reset');
    },

    onClickRow(item) {
      this.selectedId = this.selectedId === item.id ? null : item.id;
      this.$emit('selectItem', this.selectedId);
    },

    onNewSort(sort) {
      const currentSortOrder = determineCurrentSortOrder(
        sort,
        this.currentSort
      );

      this.currentSort = currentSortOrder;

      this.$emit('sort', currentSortOrder);
    },

    getConstraints(offer) {
      return {
        hasConflictingBooking: hasConflictingBookingConstraints(offer),
        isFullyBooked:
          offer.maxParticipants !== null &&
          offer.maxParticipants !== 0 &&
          offer.enrollmentsCount >= offer.maxParticipants,
      };
    },
    getConstraintClass(offer) {
      if (this.getConstraints(offer).isFullyBooked) {
        return 'bg-gradient-to-r from-error to-error/0 from-[8px] to-[8px]';
      } else if (
        this.getConstraints(offer).hasConflictingBooking ||
        this.isOnsiteOffer(offer)
      ) {
        return 'bg-gradient-to-r from-orange-600 to-orange-600/0 from-[8px] to-[8px]';
      }

      return '';
    },
    isOnsiteOffer(offer) {
      return !offer.isDistanceLearningOffer;
    },
  },
};
</script>
